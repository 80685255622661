
@import "../../scss/common/mixins";
@import "../../scss/common/variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.side-buttons {
    position: fixed;
    z-index: 99;
    top: unset;
    bottom: 20px;
    right: 10px;
    transform: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    overflow: hidden;
    padding: 10px;

    .item {
        width: 38px;
        height: 42px;
        background: #1f3d45;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        padding-left: 6px;
        box-shadow: 0 2px 8px 0 #00000033, 0 5px 2px -3px #0000001e, 0 3px 3px 0 #00000023;

        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 5px;
            left: 3px;
            width: 36px;
            height: 32px;
            border: 1px solid #a9b0af;
        }

        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 3px;
            left: 5px;
            width: 38px;
            height: 36px;
            border: 1px solid #a9b0af;
        }
    }

    .item.chat,
    .item.telephone {
        background: $navi;
    }

    .item.heart,
    .item.share {
        background: $purple;
    }

    button {
        color: $white !important;
        padding: 0 !important;
    }

    a {
        color: #fff;
        display: block;
    }

    a:hover {
        text-decoration: none;
    }

    .item.share .cli,
    .item.heart .cli {
        font-size: 24px;
    }

    .item.heart .count {
        position: absolute;
        z-index: 1;
        color: white;
        font-family: $headings-font-family;
        font-size: 11px;
    }


    .btn {
        display: block;
    }

    .item {
        width: 44px;
        height: 44px;
        border-radius: 40px;
        background: white !important;
        color: $navi;
        padding-left: 0;
        transition: ease-in-out 500ms all;

        .count {
            color: white;
        }

        &:after, &:before {
            display: none;
        }

        &.mobile-trigger {
            height: 60px;
            width: 60px;
            font-size: 22px;
            margin-top: 20px;
            display: flex;
            animation-duration: 2000ms;
            opacity: 1;

            .inner {
                position: relative;

                .badge {
                    width: 22px;
                    height: 22px;
                    border-radius: 22px;
                    background: $navi;
                    color: white;
                    font-size: 12px;
                    position: absolute;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    top: -14px;
                    right: -20px;
                    font-family: $headings-font-family;
                }
            }
        }
    }
}

