.footer {
  position: relative;
  padding-top: 80px;
  @include grainOverlay(#CFCFCF);
  font-size: 18px;

  @include media-breakpoint-down(md) {
    padding-top: 48px;
  }

  .back-top {
    width: 37px;
    height: 50px;
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.08);
    background-color: $primary;
    color: white;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 15px;
    top: 66px;

    @include media-breakpoint-down(md) {
      top: -16px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .footer-inspo {
    background: $primary;
    color: white;
    padding: 46px 62px;

    @include media-breakpoint-up(md) {
      margin-right: 45px;
    }

    @include media-breakpoint-down(md) {
      margin-bottom: 35px;
    }
  }

  .footer-top {
    padding-bottom: 68px;

    @include media-breakpoint-down(md) {
      padding-bottom: 52px;
    }

    .row {
      @include media-breakpoint-down(md) {
        padding: 0;

        .col-left {
          padding: 0;
        }

        .col-right {
          .col-md-6 {
            padding: 0 46px;
          }
        }
      }
    }
  }

  .footer-socials {
    display: flex;
    gap: 36px;

    .cli {
      font-size: 24px;
      color: white;
    }
  }

  .footer-col-title {
    color: $primary;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 12px;

    @include media-breakpoint-down(md) {
      font-size: 14px;
    }
  }

  .contact-info {
    line-height: 1.8;
    letter-spacing: normal;
    color: $dark-grey;
    margin-bottom: 34px;
    text-transform: uppercase;

    p {
      margin: 0;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 20px;
    }
  }

  .footer-nav {
    .nav-item {
      .nav-link {
        text-transform: uppercase;
        padding: 2px 0;
        color: $dark-grey;

        &:hover, &:focus {
          color: $navi;
        }
      }

      &:first-child {
        .nav-link {
          padding-top: 0;
        }
      }
    }
  }
}
