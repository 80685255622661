.property-item-slider {
    max-width: 1350px;

    .property-item-slider__wrapper {
        margin-bottom: 45px;

        @include media-breakpoint-down(sm) {
            margin-bottom: 25px;
        }
    }

    .carousel-indicators {
        bottom: auto;
        left: auto;
        position: relative;
        right: auto;

        li {
            background-color: #938e94;
            opacity: 0.5;

            &.active {
                background-color: $navi;
                opacity: 1;
            }
        }
    }
}