@font-face {
  font-family: 'icomoon';
  src:  url('./fonts/icomoon.eot?ochwea');
  src:  url('./fonts/icomoon.eot?ochwea#iefix') format('embedded-opentype'),
    url('./fonts/icomoon.ttf?ochwea') format('truetype'),
    url('./fonts/icomoon.woff?ochwea') format('woff'),
    url('./fonts/icomoon.svg?ochwea#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.cli-move:before {
  content: "\e900";
}
.cli-push-pin:before {
  content: "\e901";
}
.cli-dots-three-vertical:before {
  content: "\e902";
}
.cli-designmanual:before {
  content: "\e903";
}
.cli-pr:before {
  content: "\e904";
}
.cli-angle-down:before {
  content: "\e905";
}
.cli-linkedin:before {
  content: "\e906";
}
.cli-sell:before {
  content: "\e907";
}
.cli-swimmingpool:before {
  content: "\e908";
}
.cli-solar:before {
  content: "\e909";
}
.cli-smarthome:before {
  content: "\e90a";
}
.cli-garage:before {
  content: "\e90b";
}
.cli-document:before {
  content: "\e90c";
}
.cli-visit:before {
  content: "\e90d";
}
.cli-consultation:before {
  content: "\e90e";
}
.cli-view:before {
  content: "\e90f";
}
.cli-email-mobile:before {
  content: "\e910";
}
.cli-send-property:before {
  content: "\e911";
}
.cli-expose-request:before {
  content: "\e912";
}
.cli-menu-mobile:before {
  content: "\e913";
}
.cli-menu:before {
  content: "\e914";
}
.cli-break:before {
  content: "\e915";
}
.cli-account:before {
  content: "\e916";
}
.cli-sms:before {
  content: "\e917";
}
.cli-messenger:before {
  content: "\e918";
}
.cli-twitter:before {
  content: "\e919";
}
.cli-whatsapp:before {
  content: "\e91a";
}
.cli-share-property:before {
  content: "\e91b";
}
.cli-energy:before {
  content: "\e91c";
}
.cli-house:before {
  content: "\e91d";
}
.cli-bathroom:before {
  content: "\e91e";
}
.cli-bedroom:before {
  content: "\e91f";
}
.cli-property:before {
  content: "\e920";
}
.cli-property-number:before {
  content: "\e921";
}
.cli-layout:before {
  content: "\e922";
}
.cli-filter:before {
  content: "\e923";
}
.cli-email:before {
  content: "\e924";
}
.cli-call:before {
  content: "\e925";
}
.cli-login:before {
  content: "\e926";
}
.cli-youtube:before {
  content: "\e927";
}
.cli-times:before {
  content: "\e928";
}
.cli-arrow-right-outline:before {
  content: "\e929";
}
.cli-arrow-left-outline:before {
  content: "\e92a";
}
.cli-swipe-left:before {
  content: "\e92b";
}
.cli-swipe-right:before {
  content: "\e92c";
}
.cli-instagram:before {
  content: "\e92d";
}
.cli-facebook:before {
  content: "\e92e";
}
.cli-scroll-top:before {
  content: "\e92f";
}
.cli-agent:before {
  content: "\e930";
}
.cli-check:before {
  content: "\e931";
}
.cli-play-button:before {
  content: "\e932";
}
.cli-heart-outline:before {
  content: "\e933";
}
.cli-price:before {
  content: "\e934";
}
.cli-location:before {
  content: "\e935";
}
.cli-arrow-right:before {
  content: "\e936";
}
.cli-arrow-left:before {
  content: "\e937";
}
.cli-share:before {
  content: "\e938";
}
.cli-heart:before {
  content: "\e939";
}
.cli-telephone:before {
  content: "\e93a";
}
.cli-chat:before {
  content: "\e93b";
}
.cli-arrow-up:before {
  content: "\e93c";
}
.cli-arrow-down:before {
  content: "\e93d";
}
.cli-search:before {
  content: "\e93e";
}
