$black: #000000;
$dark: #101010;
$dark-grey: #494948;
$navi: #1f3d45;
$purple: #472234;
$yellow: #e8c875;
$grey: #f2f2f2;
$white: #ffffff;

$primary: $navi;
$secondary: $purple;

$container-max-widths: (
        xl: 1400px
);

// fonts
$font-size-base: 1rem;
$font-family-sans-serif: 'Muller', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-base: $font-family-sans-serif;
$body-color: #16181e;
$line-height-base: 1.67;
$headings-font-family: "Optima", $font-family-base;
$icons-font-family: "icomoon";
$font-weight-base: 300;

// links
$link-decoration: none;
$link-hover-decoration: none;

// input
$input-font-size: 14px;

// modal
$modal-backdrop-bg: transparentize(black, 0.6);

$carousel-control-color: #ffffff !default;
$carousel-control-icon-width: 60px !default;
$carousel-control-prev-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' x='0px' y='0px' viewBox='0 0 809 809' style='enable-background:new 0 0 809 809;' xml:space='preserve'><g><polygon points='52,406.5 757,54 757,759' /></g></svg>") !default;
$carousel-control-next-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' x='0px' y='0px' viewBox='0 0 809 809' style='enable-background:new 0 0 809 809;' xml:space='preserve'><g><polygon points='757,406.5 52,759 52,54' /></g></svg>") !default;