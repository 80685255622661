
@import "../../scss/common/mixins";
@import "../../scss/common/variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";


.property-selector-budget {
  .budget-step1 {
    .img-container {
      background: transparentize($navi, 0.5) !important;
    }
  }

  .budget-step2 {
    .img-container {
      background: transparentize($navi, 0.35) !important;
    }
  }

  .budget-step3 {
    .img-container {
      background: transparentize($navi, 0.25) !important;
    }
  }

  .budget-step4 {
    .img-container {
      background: transparentize($navi, 0.15) !important;
    }
  }

  .budget-step5 {
    .img-container {
      background: transparentize($navi, 0.05) !important;
    }
  }


  .nav-item {
    .img-container {
      &:before {
        transform: none !important;
      }
    }
  }
}

.property-selector-immobile {
  .nav-tabs {
    .nav-link {
      &.active {
        .img-container:before {
          transform: none;
        }
      }
    }
  }
}

.property-selector {
  .topline {
    .topline-title {
      text-transform: uppercase;
    }
  }

  .nav-item {
    .nav-link {
      background: transparent;
    }
  }

  &.property-selector-budget {
    .nav-tabs {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      row-gap: 0;

      @include media-breakpoint-between(sm, md) {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      }

      @include media-breakpoint-down(sm) {
        grid-template-columns: 1fr;
      }

      .nav-item {

        @include media-breakpoint-down(sm) {
          padding-top: 82px;
          order: 2;
        }

        &.budget-step_all {
          padding-top: 62px;
          grid-column: 1/-1;


          @include media-breakpoint-down(sm) {
            padding-top: 82px;
            order: 1
          }
        }
      }

      .nav-link {
        width: 172px;

        @include media-breakpoint-down(md) {
          width: 100%;
        }

        &.budget-step_all {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;

          .img-container {
            opacity: 0.5;
            background-color: rgba(31, 61, 69, 0.20);

            &:before {
              @include media-breakpoint-up(md) {
                display: none;
              }
            }
          }

          .title {
            color: $navi;

            &:before, &:after {
              border-color: transparentize($navi, 0.75);
            }

            @include media-breakpoint-up(md) {
              margin: 0;

              &:before, &:after {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}


.property-selector-immobile,
.property-selector-region {
  &__slider {
    opacity: 1;
    transition: opacity 300ms ease;

    @include media-breakpoint-up(md) {
      &.is--disabled {
        opacity: 0;
      }
    }
  }

  .nav-item {
    .nav-link {
      background: transparent;

      .animated-icon {
        position: absolute;
        top: 45px;
        width: auto;
        left: 50%;
        transform: translateX(-50%);
        transition: ease-in-out 200ms all;
        opacity: 0;


        @include media-breakpoint-down(md) {
          top: 20%;
          height: 32px;
        }


        svg {
          height: 65px;
          width: auto;

          @include media-breakpoint-down(md) {
            height: 75px;
          }

          @include media-breakpoint-down(xs) {
            height: 45px;
          }
        }
      }

      .title {
        transition: ease-in-out 200ms all;
      }

      &.option-active {
        .title {
          padding: 78px 28px 0;

          @include media-breakpoint-down(sm) {
            padding: 57px 28px 0;
          }
        }

        .animated-icon {
          opacity: 1;
        }
      }
    }
  }
}


.location-slider__control {
  height: 100%;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @include media-breakpoint-down(md) {
    display: none;
  }
}

.property-selector-container {
  .reset-filter {
    cursor: pointer;
    font-size: 14px;
    color: #888;
    margin-bottom: 15px;
  }
}

