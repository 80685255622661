.bookmarks {
    margin-top: 120px;

    &__list {
        max-width: 820px;
        width: 100%;

        &-item {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid lightgray;
            padding-bottom: 1rem;
            margin-bottom: 1rem;

            @include media-breakpoint-down(sm) {
                flex-wrap: wrap;
            }

            & > .custom-checkbox {
                flex: 0 0 50%;

                @include media-breakpoint-down(sm) {
                    flex: 0 0 100%;
                }

                & > label {
                    font-size: 17px;
                    font-weight: bold;
                    font-style: normal;
                    text-transform: none;
                }
            }

            &:last-child {
                border-bottom: none;
                padding-bottom: 0;
                margin-bottom: 0;
            }

            &-icon {
                font-size: 17px;
                font-weight: 300;
                text-transform: none;
                color: #494948;
                flex: 0 0 25%;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                &.is--align-end {
                    justify-content: flex-end;
                }

                @include media-breakpoint-down(sm) {
                    flex: 0 0 50%;

                    &:not(.is--align-end) {
                        padding-left: 25px;
                    }
                }

                & i {
                    margin-right: 0.5rem;
                    display: inline-block;

                    @media screen and (max-width: 414px) {
                        display: none;
                    }
                }

                @media screen and (max-width: 414px) {
                    padding-left: 1.4rem;
                }
            }
        }

        &-container {
            max-width: 1350px;
            padding: 3rem 0;
            margin-bottom: 5rem;
            background-color: #f2f2f2;
            display: flex;
            justify-content: center;
            @media screen and (max-width: 414px) {
                padding: 2rem;
            }
        }
    }

    .property-cards {
        margin-bottom: 0;
    }

    @include media-breakpoint-down(md) {
        margin-top: 75px;
    }
}