.page-content {
  margin-top: 120px;

  @include media-breakpoint-down(md) {
    margin-top: 75px;
  }

  .page-header {
    padding: 60px 0;

    @include media-breakpoint-down(md) {
      padding: 30px;
    }

    .page-title {
      text-align: center;
      font-size: 40px;
      text-transform: uppercase;
      position: relative;

      @include media-breakpoint-down(md) {
        font-size: 34px;
      }

      &:after {
        content: ' ';
        width: 150px;
        height: 1px;
        background: $navi;
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}