.new-development {
    &__banner {
        padding: 5rem 2.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-size: cover;
        background-position: center;
        height: 800px;

        @include media-breakpoint-down(md) {
            height: 600px;
        }

        @include media-breakpoint-down(sm) {
            height: 550px;
            padding: 5rem 1.5rem;
        }


        &-content {
            background-color: transparentize($navi, 0.25);
            padding: 3rem;
            width: 500px;
            margin: 0 auto;
            color: white;

            .first-title {
                color: white;
                max-width: unset;
            }

            @include media-breakpoint-down(md) {
                padding: 1.5rem;
            }

            @include media-breakpoint-down(sm) {
                padding: 1rem;
            }

            .btn {
                @include media-breakpoint-down(sm) {
                    font-size: 12px;
                    line-height: 26px;
                }
            }
        }
    }
}