

@import "../../scss/common/mixins";
@import "../../scss/common/variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.search-query {
  padding: 0 50px;
  margin-top: 50px;

  .search-query__current {
    font-size: 26px;
    text-align: center;
    margin-bottom: 5px;

    strong {
      font-weight: bold;
      color: $navi;
    }
  }

  .search-query__reset {
    text-align: center;
    cursor: pointer;
    font-size: 14px;
    color: #888;
    margin-bottom: 15px;

    &:hover {
      color: $navi;
    }
  }
}
