
@import "../../scss/common/mixins";
@import "../../scss/common/variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.required-fields-notice {
    font-size: 14px;
    font-style: italic;
    color: white;
}

.input-error {
    font-size: 12px;
    font-weight: bold;
    color: white;
    text-align: left;
    margin-bottom: 4px;
}

.form-control {
    &.has--errors {
        border: 1px solid white;
    }
}

.has--errors {
    .custom-control-label {
        &:before {
            border: 1px solid white;
        }
    }
}

.news-container {
    margin-bottom: 15px;

    &--phone {
        margin-bottom: 35px;
    }

    .custom-checkbox {
        margin: 0 !important;
    }
}

