.modal-password-reset-form {
    background: $white;
    @include noisyBackground();
    border: 1px solid $purple;
    margin: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) !important;
    width: 450px;

    @include media-breakpoint-down(md) {
        left: 50%;
        right: auto;
        top: 50%;
        transform: translate(-50%, -50%) !important;
        width: 90%;
    }

    .modal-content {
        background: none;
        border: 0 none;
        padding: 37px 30px 43px 30px;
    }

    .modal-header {
        border-bottom: 0;
        display: block;
        padding: 0;
        text-align: center;

        .title {
            color: $dark-grey;
            font-family: 'Optima', sans-serif;
            font-size: 25px;
            letter-spacing: 2px;
            line-height: 1.12;
            padding-bottom: 10px;
            position: relative;
            text-transform: uppercase;

            &:after {
                background: $dark-grey;
                bottom: 0;
                content: '';
                height: 1px;
                left: 50%;
                opacity: 0.5;
                position: absolute;
                transform: translate(-50%, 0);
                width: 184px;
            }
        }

        .subtitle {
            color: $dark-grey;
            font-family: 'Muller', sans-serif;
            font-size: 14px;
            letter-spacing: 2px;
            line-height: 1.43;
            margin-bottom: 23px;
            margin-top: 7px;
            text-transform: uppercase;
        }

        .close {
            color: $dark-grey;
            cursor: pointer;
            opacity: 0.5;
            position: absolute;
            right: 13px;
            top: 13px;

            i {
                display: block;
                font-size: 11px;
                margin-right: 0;
            }
        }
    }

    .modal-body {
        padding: 0;

        .input-error {
            text-align: left;
            color: $navi;
        }

        p {
            font-family: 'Muller', sans-serif;
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 10px;
            text-align: center;

            &.share {
                margin-top: 11px;
            }
        }

        ul {
            display: flex;
            justify-content: center;
            margin: 0;
            padding: 0;

            li {
                list-style: none;
                margin-right: 13px;

                &:last-child {
                    margin-right: 0;
                }

                a {
                    color: $purple;

                    i {
                        font-size: 35px;
                        line-height: 37px;

                        &.cli-email-mobile {
                            font-size: 26px;
                        }

                        &.cli-linkedin {
                            font-size: 41px;
                        }
                    }
                }
            }
        }
    }
}