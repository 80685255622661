

.modal-container {
    position: fixed;
    bottom: 0;
    left: unset;
    z-index: 99;
    right: 0;
    display: block;
    //   transform: translateY(-50%);
    height: max-content;
    width: max-content;
    background: white;
    min-width: 150px;
    min-height: 150px;
    box-shadow: 0px 0px 2.1px rgba(0, 0, 0, 0.037),
    0px 0px 5.1px rgba(0, 0, 0, 0.053),
    0px 0px 9.5px rgba(0, 0, 0, 0.065),
    0px 0px 17px rgba(0, 0, 0, 0.077),
    0px 0px 31.8px rgba(0, 0, 0, 0.093),
    0px 0px 76px rgba(0, 0, 0, 0.13);

    .modal-snippet {
        &__image {
            img {
                max-height: 267px;
                width: 100%;
                object-fit: contain;
            }
        }

        &__content {
            padding: 20px 60px;

            h1, h2, h3, h4, h5, h6 {
                text-transform: uppercase;
            }

            .toolbox-element.wysiwyg {
                margin: unset;
                max-width: unset;
            }

            .btn {
                width: 100%;
            }
        }
    }

    // close button
    button {
        position: absolute;
        top: 0;
        right: 0;
        margin: 15px;
        color: white;
        stroke: white;
        cursor: pointer;
        background: none;
        border: none;

        i {
            font-size: 20px;
            text-shadow: 0px 0px 0.7px rgba(0, 0, 0, 0.281),
            0px 0px 1.6px rgba(0, 0, 0, 0.404),
            0px 0px 3px rgba(0, 0, 0, 0.5),
            0px 0px 5.4px rgba(0, 0, 0, 0.596),
            0px 0px 10px rgba(0, 0, 0, 0.719),
            0px 0px 24px rgba(0, 0, 0, 1);
        }
    }

    &-blur {
//   backdrop-filter: blur(10px);
    }

    &-darker {
//   background: rgba(0, 0, 0, 0.5);
    }

    &-top-center > .v-overlay__content {
        top: 0;
    }

    &-top-left > .v-overlay__content {
        top: 0;
        left: 0;
    }

    &-top-right > .v-overlay__content {
        top: 0;
        right: 0;
    }

    &-bottom-center > .v-overlay__content {
        bottom: 0;
    }

    &-bottom-left > .v-overlay__content {
        bottom: 0;
        left: 0;
    }

    &-bottom-right > .v-overlay__content {
        bottom: 0;
        right: 0;
    }

    &-center-left > .v-overlay__content {
        left: 0;
    }

    &-center-right > .v-overlay__content {
        right: 0;
    }
}

@media screen and (max-width: 768px) {
    .modal-container {
        right: 50%;
        transform: translateX(50%);
    }
}
