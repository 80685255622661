.brick-team-grid {
    max-width: 1320px;
    margin: 0 auto;
    padding: 80px 0;
    position: relative;
    @include grainOverlay($grey,1);

    .brick-special-heading{
        position: relative;
        z-index: 2;
    }

    .team-grid-wrapper {
        position: relative;
        z-index: 2;
    }

    .team-grid-member-wrapper {
        display: grid;
        grid-template-areas: ".team-grid__member";
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 20px;
        grid-row-gap: 80px;
        max-width: 940px;
        margin: 0 auto;
    }

    .team-grid-member {
        border-bottom: 1px solid rgba(73, 73, 72, 0.5);
        padding-bottom: 15px;

        &__image {
            display: block;
            height: auto;
            width: 100%;
        }

        &__name {
            color: $dark-grey;
            font-family: "Muller", sans-serif;
            font-size: 22px;
            font-weight: 400;
            line-height: 1.09;
            margin-bottom: 10px;
            margin-top: 27px;
            text-align: center;
            text-transform: uppercase;
        }

        &__position {
            color: $black;
            font-family: "Muller", sans-serif;
            font-size: 14px;
            font-weight: 500;
            line-height: 1.21;
            margin-bottom: 6px;
            opacity: 0.5;
            text-align: center;
            text-transform: uppercase;
        }

        &__mail {
            line-height: 1.44;
            margin-bottom: 0;
            text-align: center;
            word-break: break-all;
        }

        &__phone {
            text-align: center;
        }
    }

    .carousel-control-prev,
    .carousel-control-next {
        color: $dark-grey;
        font-size: 25px;
        max-height: 415px;
    }

    .carousel-indicators {
        margin-bottom: 0;
        margin-top: 28px;
        position: relative;

        li {
            background: $navi;
        }
    }

    @include media-breakpoint-down(md) {
        .team-grid-member-wrapper {
            grid-template-columns: 1fr 1fr;
        }
    }

    @include media-breakpoint-down(sm) {
        .team-grid-member-wrapper {
            display: flex;
        }

        .keen-slider {
            margin: 0 auto;
            max-width: 270px;
        }

        .team-grid-member {
            &__name {
                font-size: 16px;
            }

            &__position {
                font-size: 12px;
            }
        }
    }
}