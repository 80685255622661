.property-information {
    max-width: 1348px;

    .property-information__image-col {
        padding-left: 0;
        padding-right: 0;
        position: relative;
        @include grainOverlay(transparent, 2);

        .property-information__image-wrapper {
            &:before {
                background: #f2f2f2;
                content: '';
                height: 1px;
                left: 0;
                opacity: 0.75;
                position: absolute;
                right: 20px;
                top: 20px;
            }

            &:after {
                background: #f2f2f2;
                bottom: 20px;
                content: '';
                height: 1px;
                left: 0;
                opacity: 0.5;
                position: absolute;
                right: 20px;
            }

            .property-information__image {
                width: 100%;
                z-index: 5;
                height: auto;
            }

            .divider {
                background: #f2f2f2;
                bottom: 35px;
                opacity: 0.5;
                position: absolute;
                right: 20px;
                top: 35px;
                width: 1px;
            }
        }

        @include media-breakpoint-down(md) {
            margin-bottom: 25px;
        }
    }

    .property-information__row {
        font-weight: 300;
        line-height: 1;

        > td {
            padding: 15px 5px;
        }

        .property-information__icon {
            width: 45px;

            .cli {
                font-size: 22px;
            }
        }

        .property-information__label {
            border-bottom: 1px solid rgba(192, 192, 192, 0.5);
            font-size: 18px;
            font-weight: 300;

            @include media-breakpoint-down(md) {
                font-size: 16px;
            }
        }
    }

    .quicklinks-wrapper {
        margin-top: 40px;

        @include media-breakpoint-down(sm) {
            border-top: 1px solid rgba(192, 192, 192, 0.5);
            margin-top: 80px;
        }
    }

    .property-information__quicklinks-link {
        border-bottom: 1px solid rgba(192, 192, 192, 0.5);
        color: $navi;
        font-family: $headings-font-family;
        font-size: 14px;
        font-weight: 700;
        min-width: 220px;
        letter-spacing: 2px;

        .cli {
            min-width: 35px;

            @include media-breakpoint-down(sm) {
                min-width: unset;
            }
        }

        &:hover {
            color: $purple;
            text-decoration: none;
        }

        @include media-breakpoint-down(sm) {
            min-width: unset;
            width: 100%;
            font-size: 12px;
            letter-spacing: 1px;
        }

    }

    @include media-breakpoint-up(sm) {
        .property-information__image-col {

            .property-information__image-wrapper {
                &:before {
                    right: 45px;
                    top: 75px;
                }

                &:after {
                    bottom: 75px;
                    right: 45px;
                }

                .divider {
                    bottom: 115px;
                    right: 45px;
                    top: 115px;
                }
            }
        }
    }
}

.video-direct-link {
    .property-information .quicklinks-wrapper {
        margin-top: 0;

        .property-information__quicklinks-link {
            border-bottom: 0 none;
            border-top: 1px solid rgba(192, 192, 192, 0.5);
            color: $white;

            &--has-border-bottom {
                border-bottom: 1px solid rgba(192, 192, 192, 0.5);
            }

            &:hover {
                color: $white;
            }
        }
    }
}