.full-width-video {
    .card-body {
        padding-bottom: 0;
    }

    .full-width-video__item {
        height: 206px;
    }

    @include media-breakpoint-up(sm) {
        .full-width-video__item {
            height: 550px;
        }
    }
}

.video-direct-link {
    .full-width-video {
        margin: 0 auto;
        max-width: 970px;
    }
}