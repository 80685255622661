.modal-secret-sales {
    position: absolute;
    bottom: 0;
    left: 374px;
    margin: 0;
    transform: none !important;
    @include noisyBackground();
    box-shadow: 0 1px 3px 0 #00000033, 0 2px 1px -1px #0000001e, 0 1px 1px 0 #00000023;

    @include media-breakpoint-down(md) {
        left: 50%;
        bottom: unset;
        top: 50%;
        transform: translate(-50%, -50%) !important;
    }

    .input-error {
        text-align: left;
        color: $navi;
        font-size: 12px;
        font-weight: bold;
        margin-bottom: 4px;
    }

    .form-control {
        &.has--errors {
            border: 1px solid $navi;
        }
    }

    .has--errors {
        .custom-control-label {
            &:before {
                border-color: $navi;
            }
        }
    }
}

.modal-secret-sales .modal-header {
    position: absolute;
    width: 100%;
    border: none;
}

.modal-secret-sales .close {
    display: inline-block;
    font-family: $icons-font-family;
    font-size: 14px;
    text-indent: 0;
    position: relative;
    height: 14px;
    width: 14px;
    padding: 0;
    margin: 0;
    color: transparent;
    opacity: 1;
    transform: translate(-14px, 9px);
    z-index: 11;
}

.modal-secret-sales .close:active,
.modal-secret-sales .close:focus {
    outline: none !important;
}

.modal-secret-sales .close:before {
    content: '\e928';
    position: absolute;
    top: 0;
    left: 0;
    color: #949494;
}

.modal-secret-sales .modal-content {
    width: 315px;
    border: none;
    border-radius: 0;
}

.modal-secret-sales .modal-footer {
    display: none;
}

.modal-secret-sales .close:before {
    content: '\e928';
    position: absolute;
    top: 0;
    left: 0;
    color: #949494;
}

.modal-secret-sales .modal-secret-sales__title {
    font-size: 22px;
    line-height: 1.3;
    letter-spacing: 2px;
    text-align: center;
    color: #494948;
    padding: 0 30px;
    margin-top: 28px;
    margin-bottom: 14px;
    font-family: $headings-font-family;
    text-transform: uppercase;
    text-align: center;
}

.modal-secret-sales .modal-body {
    padding: 16px 30px;
    @include noisyBackground();

    @include media-breakpoint-down(md) {
        max-height: 75vh;
        overflow-y: scroll;
    }
}

.modal-secret-sales .form-group {
    margin-bottom: 10px;
}

.modal-secret-sales .btn {
    height: 36px;
    margin: auto;
    margin-top: 25px;
    display: block;
    margin-bottom: 24px;
    line-height: 20px;
    font-size: 16px;
    letter-spacing: 2.4px;

    &:before {
        height: 36px;
    }

    &:after {
        height: 44px;
    }

    @include media-breakpoint-down(sm) {
        margin: 12px auto;
    }
}

.modal-secret-sales .bottom {
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 17px;
}

.modal-secret-sales .bottom a {
    font-size: 13px;
    color: #494948;
    text-decoration: underline;
    letter-spacing: 0;
}

.modal-secret-sales .bottom a:hover {
    text-decoration: none;
}


#modal-secret-login-pw-recover .step2 p {
    font-size: 14px;
    color: $dark-grey;
    text-align: center;
}

#modal-secret-login-pw-recover .step2 p a {
    color: $navi;
}


#modal-secret-login-pw-recover .step2 p {
    font-size: 14px;
    color: $dark-grey;
    text-align: center;
}

#modal-secret-login-pw-recover .step2 p a {
    color: $navi;
}