.modal-call-back-service {
    background: $white;
    @include noisyBackground();
    border: 1px solid $navi;
    margin: 0;
    position: absolute;
    right: 50px;
    top: 50%;
    transform: translate(0, -50%) !important;
    width: 310px;

    @include media-breakpoint-down(md) {
        left: 50%;
        right: auto;
        top: 50%;
        transform: translate(-50%, -50%) !important;
    }

    .modal-content {
        background: none;
        border: 0 none;
    }

    .modal-header {
        align-items: center;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0);
        display: flex;
        height: 70px;
        justify-content: center;

        i {
            margin-right: 15px;
        }

        p {
            font-family: 'Muller', sans-serif;
            font-size: 19px;
            margin-bottom: 0;

            a {
                color: $navi;
            }
        }

        .close {
            cursor: pointer;
            position: absolute;
            right: 13px;
            top: 13px;

            i {
                display: block;
                font-size: 11px;
                margin-right: 0;
            }
        }
    }

    .modal-body {
        padding: 35px 30px;

        &__form {
            .form-control {
                margin-bottom: 8px;
                padding: 15px 16px;
                font-size: 12px;
                font-weight: normal;
                text-overflow: ellipsis;
                text-transform: uppercase;
                white-space: nowrap !important;
            }

            label.form-control {
                margin-bottom: 0;
            }

            .acceptance {
                font-family: 'Muller', sans-serif;
                font-size: 11px;
                line-height: 1.45;
            }

            .inquiry-call-back-service__button {
                width: 197px;
            }
        }

        &__headline {
            text-align: center;

            .title {
                color: $dark-grey;
                font-family: 'Optima', sans-serif;
                font-size: 25px;
                letter-spacing: 2px;
                line-height: 1.12;
                padding-bottom: 10px;
                position: relative;
                text-transform: uppercase;

                &:after {
                    background: $dark-grey;
                    bottom: 0;
                    content: '';
                    height: 1px;
                    left: 50%;
                    opacity: 0.5;
                    position: absolute;
                    transform: translate(-50%, 0);
                    width: 184px;
                }
            }

            .subtitle {
                color: $dark-grey;
                font-family: 'Muller', sans-serif;
                font-size: 14px;
                letter-spacing: 2px;
                line-height: 1.43;
                margin-top: 7px;
                text-transform: uppercase;
            }
        }
    }
}