@-webkit-keyframes exposeBadgePulsate {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.02, 1.02);
    opacity: 1.0;
  }
  100% {
    transform: scale(1, 1);
  }
}

.property-detail {

  .card {
    background: none;
  }

  .property-text-block {
    font-family: $font-family-base;
    font-size: 18px;
    line-height: 1.67;
    font-weight: 300;

    @include media-breakpoint-down(md) {
      font-size: 16px;
    }
  }

  .property-hero-slider {
    .property-expose-badge {
      display: block;
      position: absolute;
      top: 100px;
      right: 50px;
      z-index: 20;

      @include media-breakpoint-down(sm) {
        top: 60px;
        right: 5px;
      }

      .expose-badge {
        width: 160px;
        height: auto;
        transition: ease-in-out 200ms transform;
        animation: exposeBadgePulsate 1s ease-out;
        animation-iteration-count: infinite;

        @include media-breakpoint-down(sm) {
          width: 120px;
        }
      }
    }

    .brick-special-heading {
      position: absolute;
      bottom: 100px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 200;

      .first-title {
        color: white;
      }

      @include media-breakpoint-down(sm) {
        bottom: 10px;
      }
    }

    .carousel-control-next,
    .carousel-control-prev {
      opacity: 1;
      z-index: 10;
      height: 90%;
      bottom: 0;
      top: auto;
    }

    .carousel-indicators {
      li {
        @include media-breakpoint-up(sm) {
          height: 15px;
          width: 15px;
        }
      }
    }

    &.has--content-below-pagination {
      .carousel-indicators {
        margin-bottom: 120px;

        @include media-breakpoint-down(sm){
          margin-bottom: 110px;
        }
      }
    }

    .property-hero-slider__cta {
      position: absolute;
      left: 50%;
      bottom: 38px;
      transform: translateX(-50%);
      z-index: 12;
      width: 100%;
      display: flex;
      justify-content: center;

      @include media-breakpoint-down(sm){
        bottom: 30px;
      }
    }

  }
}