.references-slider {
    margin: 0 auto;
    max-width: 1108px;

    &-wrapper {
        padding: 0 114px;
        position: relative;
    }

    .carousel-control-prev {
        left: 22px;
    }

    .carousel-control-next {
        right: 22px;
    }

    .carousel-control-prev,
    .carousel-control-next {
        background: none;
        border: 0 none;
        opacity: 1;
        padding: 0;
        width: 47px;
    }

    .carousel-control-prev-icon,
    .carousel-control-next-icon {
        font: normal normal normal 25px/1 Cumlaudeimmobilia;
        background: none;
        height: auto;
    }

    .carousel-control-prev-icon:after {
        content: '\e92a';
    }

    .carousel-control-next-icon:after {
        content: '\e929';
    }

    .carousel-indicators {
        bottom: auto;
        left: auto;
        margin-top: 5rem;
        position: relative;
        right: auto;

        li {
            background: #373737;

            &.active {
                background: $yellow;
            }
        }
    }

    &-avatar {
        display: block;
        border-radius: 50%;
        overflow: hidden;
        margin-bottom: 1rem;
        width: 125px;
        height: 125px;
        margin: 0px auto 16px;
    }

    &-name {
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: italic;
        line-height: 1.33;
        letter-spacing: 3.02px;
        text-align: center;
        color: $body-color;
    }

    &-location {
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: italic;
        line-height: 1.25;
        letter-spacing: 0.86px;
        text-align: center;
        color: $dark-grey;
        margin-bottom: 1rem;
    }

    &-text {
        font-size: 15px;
        font-weight: 300;
        font-stretch: normal;
        font-style: italic;
        line-height: 1.53;
        letter-spacing: normal;
        text-align: center;
        color: $body-color;
        margin-bottom: 3rem;
        position: relative;

        &::after {
            content: '';
            width: 150px;
            height: 1px;
            background-color: #a0a0a0;
            position: absolute;
            bottom: -1.5rem;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &-extra-text {
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.07;
        letter-spacing: normal;
        text-align: center;
        color: #e8c875;
    }

    @include media-breakpoint-down(sm) {
        &-wrapper {
            padding-left: 69px;
            padding-right: 69px;
        }

        .carousel-control-prev-icon,
        .carousel-control-next-icon {
            font-size: 22px;
        }

        .carousel-indicators {
            margin-top: 2rem;
        }
    }
}

.dark {
    .references-slider {
        &-name {
            color: #f5f5f5;
        }

        &-location {
            color: #a0a0a0;
        }

        &-text {
            color: #f5f5f5;

            &::after {
                background-color: #a0a0a0;
            }
        }

        &-extra-text {
            color: #e8c875;
        }
    }
}