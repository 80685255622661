
@import "../../scss/common/mixins";
@import "../../scss/common/variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.newsletter-slide {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  text-align: left;

  @include media-breakpoint-down(md) {
    text-align: center;
    font-size: 14px;
  }

  .newsletter-slide__inner {
    margin-top: 200px;

    @include media-breakpoint-up(lg) {
      margin-top: 300px;
    }
  }

  .newsletter-slide__title {
    font-family: $headings-font-family;
    color: white;
    font-size: 34px;
    font-weight: 500;
    line-height: 1.3;
    letter-spacing: 3px;
    text-transform: uppercase;

    @include media-breakpoint-up(md) {
      padding-right: 40px;
    }

    @include media-breakpoint-down(md) {
      font-size: 20px;
    }

    &:after {
      content: '';
      display: block;
      height: 0.75px;
      width: 300px;
      opacity: 0.5;
      background: white;
      margin: 15px 0 30px;

      @include media-breakpoint-down(lg) {
        width: 90%;
      }

      @include media-breakpoint-down(sm) {
        width: 100%;
        margin: 12px auto 15px;
      }
    }
  }

  .checkboxes {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 15px;

    @include media-breakpoint-down(md) {
      justify-content: center;
    }

    .has--border-right {
      border-right: 1px solid #aaa;
      margin-right: 30px;
      padding-right: 30px;
    }

    .has--errors {
      .custom-control-label {
        &:before {
          border-color: $navi;
        }
      }
    }
  }

  .email-input {
    background: rgba(white, 0.5);
    border: 1px solid white;
    text-transform: uppercase;
    color: $navi;
    padding: 12px 24px;
    font-size: 18px;

    @include media-breakpoint-down(md) {
      font-size: 14px;
    }

    &::placeholder {
      color: rgba($navi, 0.8);
    }
  }

  .custom-checkbox {
    font-style: italic;
    text-align: left;

    a {
      color: white;
      text-decoration: underline;
    }

    .custom-control-label {
      @include media-breakpoint-down(md) {
        font-size: 14px;
      }

      margin: 0
    }
  }

  .btn {
    @include media-breakpoint-down(sm) {
      margin: 0 -15px;
    }
  }

  .form-success {
    text-align: center;
    padding: 50px 0;

    @include media-breakpoint-up(md) {
      width: 60%;
      margin: 0 auto;
      padding: 0;
    }

    .newsletter-slide__title {
      padding: 0;

      &:after {
        display: none;
      }
    }
  }
}
