.realtor {
    max-width: 495px;

    .realtor__image {
        position: relative;
        width: 132px;
        z-index: 2;
        height: 132px;
        object-fit: cover;
        object-position: center;
    }

    .realtor__content {
        min-height: 132px;
        position: relative;
        z-index: 2;

        .realtor__name {
            color: $dark-grey;
            font-size: 17px;
            font-weight: 500;
            line-height: 1.06;
            margin-bottom: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .realtor__departure {
            color: $dark-grey;

            font-size: 14px;
            font-style: italic;
            font-weight: 400;
            line-height: 1.29;
        }

        .realtor__mail {
            font-weight: 300;
            font-size: 14px;
            line-height: 1.21;

            a {
                color: $dark-grey;
                word-break: break-all;

                &:hover {
                    color: $purple;
                }
            }
        }

        .realtor__phone {
            font-weight: 300;
            font-size: 14px;
            line-height: 1.21;
            margin-bottom: 0;

            a {
                color: $dark-grey;
                word-break: break-all;

                &:hover {
                    color: $purple;
                }
            }
        }
    }

    .divider {
        &:before {
            border: 1px solid $dark-grey;
            bottom: 7px;
            content: '';
            left: 66px;
            position: absolute;
            right: 0;
            top: 7px;
            z-index: 1;
        }

        &:after {
            border: 1px solid $dark-grey;
            bottom: 3px;
            content: '';
            left: 66px;
            position: absolute;
            right: 5px;
            top: 3px;
            z-index: 1;
        }
    }

    @include media-breakpoint-up(sm) {
        .realtor__image {
            width: 197px;
            height: 197px;
        }

        .realtor__content {
            min-height: 197px;
        }

        .divider {
            &:before {
                left: 98px;
            }

            &:after {
                left: 98px;
            }
        }
    }
}

.dark {
    .realtor {
        .realtor__name {
            color: $white;
        }

        .realtor__departure {
            color: $white;
        }

        .realtor__mail {
            a {
                color: $white;

                &:hover {
                    color: $white;
                }
            }
        }

        .realtor__phone {
            color: $white;

            a {
                color: $white;

                &:hover {
                    color: $white;
                }
            }
        }

        .divider {
            &:before {
                border: 1px solid $white;
            }

            &:after {
                border: 1px solid $white;
            }
        }
    }
}