@mixin noisyBackground() {
  background: white;
  background: url("../../images/noise-overlay.jpg");
  background-repeat: repeat;
}

@mixin darkBackground() {
  background-color: $dark;
  background-image: url("../../images/structure-overlay.png");
  background-repeat: repeat;
}

@mixin purpleBackground() {
  background-color: $purple;
  background-image: url("../../images/structure-overlay.png");
  background-repeat: repeat;
}

@mixin grainOverlay($bgColor: $navi, $zindex: -1) {
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $bgColor;
    background-image: url("../../images/noise-overlay.png");
    background-repeat: repeat;
    z-index: $zindex;
  }
}
