@import "../fonts/stylesheet.css";
@import "../icons/style.css";

// === FONTS ===
@import "common/variables";

// === 3rd Party libraries ===
@import "./bootstrap";
@import '~baguettebox.js/dist/baguetteBox.min.css';
@import "~aos/dist/aos.css";
@import "~animate.css/animate.css";
@import "~vue2-animate/dist/vue2-animate.css";
@import "~vue-googlemaps/dist/vue-googlemaps.css";

// === COMMON ===
@import "common/typo";
@import "common/mixins";
@import "common/util";
@import "common/global";

// === COMPONENTS ===
@import "components/buttons";
@import "components/dropdowns";
@import "components/alert";
@import "components/timepickers";

// === MODULES ===
@import "modules/navigation";
@import "modules/full-width-image";
@import "modules/full-width-video";
@import "modules/hero-image-slider";
@import "modules/image-grid";
@import "modules/inquiry-call-back-service";
@import "modules/property-information";
@import "modules/property-item";
@import "modules/property-item-slider";
@import "modules/property-map";
@import "modules/realtor";
@import "modules/reference-slider";
@import "modules/region-information";
@import "modules/special-heading";
@import "modules/secret-sales-modals";
@import "modules/newsletter-section";
@import "modules/baguettebox";
@import "modules/call-to-action-overlay";
@import "modules/team-grid";
@import "modules/footer";
@import "modules/modal-share";
@import "modules/overlay-content-teaser";
@import "modules/media-download";
@import "modules/featured-image";
@import "modules/modal-password-reset-form";

// === LAYOUTS ===
@import "layouts/bookmarks";
@import "layouts/dark";
@import "layouts/page-content";
@import "layouts/property-detail";
@import "layouts/property-search";
@import "layouts/purple";
@import "layouts/secret-sales";
@import "layouts/video-direct-link";