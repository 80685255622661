.region-information {
  .card-body {
    padding-bottom: 20px;
  }

  .region-information__content {
    max-width: 938px;
  }

  .region-information__image-col {
    @include media-breakpoint-down(sm) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .region-information__image-wrapper {
    position: relative;

    &:before {
      background: #f2f2f2;
      content: '';
      height: 1px;
      left: 32px;
      opacity: 0.5;
      position: absolute;
      right: 32px;
      top: 32px;
    }

    &:after {
      background: #f2f2f2;
      bottom: 70px;
      content: '';
      opacity: 0.5;
      position: absolute;
      right: 32px;
      top: 70px;
      width: 1px;
    }

    .region-information__image {
      width: 100%;
      z-index: 9999;
      object-fit: cover;
      object-position: center;
      height: 600px;

      @include media-breakpoint-down(sm) {
        height: 350px;
      }
    }
  }
}