
@import "../../scss/common/mixins";
@import "../../scss/common/variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.slide-in-modal {
  .modal-dialog {
    margin: 0 0 0 auto;
    top: 50%;

    @include media-breakpoint-down(sm) {
      max-width: 98%;
    }

    .modal-content {
      border: none;
      border-radius: 0;
      @include noisyBackground();
    }

    .modal-title {
      height: 1px;
      background: $dark-grey;
      opacity: 0.25;
      width: calc(100% - 60px);
      margin-left: auto;
      position: relative;

      &:before {
        content: '\E92F';
        font-family: $icons-font-family;
        position: absolute;
        left: -50px;
        top: 50%;
        transform: translateY(-50%) rotate(90deg);
        font-size: 38px;
      }
    }

    .modal-body {
      border: none;
      border-radius: 0;
      padding: 52px 82px;

      @include media-breakpoint-down(sm) {
        padding: 40px 62px;
      }
    }

    .modal-header {
      border: none;
      border-radius: 0;
      padding: 55px 33px 0;
    }

    .slide-in-modal__title {
      font-family: $headings-font-family;
      font-weight: 500;
      color: $dark-grey;
      letter-spacing: 3px;
      font-size: 34px;
      text-transform: uppercase;
      line-height: 1.2;

      @include media-breakpoint-down(sm) {
        font-size: 28px;
      }

      &:after {
        content: '';
        display: block;
        margin: 12px 0;
        height: 0.75px;
        background: $dark-grey;
        width: 240px;
        opacity: 0.5;
        position: relative;
      }
    }

    .slide-in-modal__close {
      color: $gray-600;
      padding: 0;
    }
  }

  &.fade {
    .modal-dialog {
      transform: translate(100%, -50%) !important;
    }

    transition: all 400ms ease-in-out;
  }

  &.show {
    .modal-dialog {
      transform: translate(0, -50%) !important;
    }
  }
}
