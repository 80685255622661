
@import "../../scss/common/mixins";
@import "../../scss/common/variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.navbar-search {
  margin-left: 30px;
  width: 320px;
  transition: width 300ms ease-in-out;

  @include media-breakpoint-down(lg) {
    margin-left: 20px;
  }

  @include media-breakpoint-down(sm) {
    margin-left: 15px;
  }

  .input-group {
    background: $grey;
    border: 0.75px solid rgba(73, 73, 72, 0.40);
    border-radius: 100px;
    width: 100%;
    overflow: hidden;
    min-height: 34px;

    @include media-breakpoint-down(lg) {
      flex-wrap: nowrap;
    }

    .input-group-append {
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .form-control {
      background: transparent;
      height: 34px;
      padding-left: 20px;
      letter-spacing: 0;

      @include media-breakpoint-down(lg) {
        left: -100%;
        padding: 0;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    width: 40px;

    &.is--open {
      width: 255px;

      .input-group {
        .form-control {
          left: 0;
          padding-left: 15px;
        }
      }
    }
  }

}
