.hero-image-slider {
  position: relative;
  height: 100vh;
  width: 100%;

  .hero-image-slider__progress {
    background: $navi;
    border-radius: 0;
    bottom: 0;
    height: 9px;
    display: none;

    .hero-image-slider__progress-bar {
      background: #ffffff;
      height: 9px;
      left: 0;
      position: absolute;
      top: 0;
    }
  }

  .hero-image-slider__button {
    background: none;
    border: 0 none;
    bottom: 30px;
    color: #ffffff;
    font-size: 12px;
    left: 18px;
    letter-spacing: 1px;
    z-index: 800;
    display: none;

    .cli {
      font-size: 28px;
    }

    .hero-image-slider__button-label {
      font-family: 'Muller', sans-serif;
      margin-top: 9px;
    }
  }

  .carousel-inner {
    z-index: 5;
  }

  .carousel-control-prev {
    bottom: auto;
    left: 0;
    top: 0;
    width: 10%;
    z-index: 10;
    height: 100%;
    cursor: pointer;
    justify-content: flex-start;
    padding-left: 30px;

    @include media-breakpoint-down(md) {
      left: 0px;
      padding-left: 15px;
    }
  }

  .carousel-control-next {
    bottom: auto;
    right: 0;
    top: 0;
    width: 10%;
    z-index: 10;
    height: 100%;
    cursor: pointer;
    justify-content: flex-end;
    padding-right: 65px;

    @include media-breakpoint-down(md) {
      right: 0px;
      padding-right: 15px;
    }
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    height: 26px;
    width: 26px;
  }

  .hero-image-slider__overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 30%;
    width: 100%;
    z-index: 10;
    @include gradient-y(transparentize(black, 0.99), transparentize(black, 0.6));
  }

  .carousel-item {
    position: relative;
    @include grainOverlay(transparent, 2);

    &.promo-slide {
      @include grainOverlay(rgba($navi, 0.8), 2);

      .carousel-caption {
        right: 10%;
        left: 10%;

        @include media-breakpoint-down(sm) {
          bottom: 70px;
        }
      }
    }
  }

  .carousel-indicators {
    @include media-breakpoint-down(md) {
      margin-bottom: 35px;
    }
  }

  @include media-breakpoint-up(sm) {
    .hero-image-slider__button {
      bottom: 37px;
      left: 70px;
    }

    .carousel-control-prev-icon,
    .carousel-control-next-icon {
      height: 34px;
      width: 34px;
    }
  }
}

.hero-slider {
  height: 100vh;
  width: 100%;
  position: relative;

  .carousel-item {
    position: relative;
    height: 100vh;

    .img-fluid {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }

    video {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 100vw;
      min-height: 100vh;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}

.brick-hero-image-slider {
  position: relative;

  .text {

    @include media-breakpoint-up(sm) {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .title {
      @include media-breakpoint-up(sm) {
        margin-right: 5px;
      }

      @include media-breakpoint-down(sm) {
        margin-bottom: 10px !important;
      }
    }

    .title, .subtitle {
      font-size: 42px;
      font-family: $headings-font-family;
      margin: 0;
      line-height: 1;
    }
  }

  .btn {
    @include media-breakpoint-down(sm) {
      font-size: 12px;
      line-height: 25px;
    }
  }

  .carousel-item {
    position: relative;
    @include grainOverlay(transparent, 2);
  }

  .carousel-caption {
    left: 0;
    right: 0;
    bottom: 0;
    padding-right: 10%;
    padding-left: 10%;
    padding-bottom: 100px;
    width: 100%;
  }

  .animation-container {
    position: relative;
    z-index: 5;
  }

  .hero-image-slider__overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    @include gradient-y(transparentize(black, 0.99), transparentize(black, 0.6));
  }
}

.carousel {
  .carousel-indicators {
    li {
      &:focus, &:hover {
        outline: none;
        border: none;
      }
    }
  }
}
