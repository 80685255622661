.brick-special-heading {
    text-align: center;

    .card {
        border: none;
    }

    .first-title {
        font-size: 35px;
        line-height: 1.23;
        letter-spacing: 3.00px;
        text-align: center;
        margin: 0;
        font-weight: 300;
        text-transform: uppercase;
        font-family: $headings-font-family;


        @include media-breakpoint-up(md) {
            max-width: 40%;
            margin: 0 auto;
        }

        @include media-breakpoint-down(md) {
            max-width: 80%;
        }

        @include media-breakpoint-down(sm) {
            font-size: 23px;
            max-width: unset;
        }

        &:after {
            content: "";
            max-width: 420px;
            width: 100%;
            height: 1px;
            background: #c0c0c0;
            display: block;
            margin: 10px auto;
            opacity: 0.75;

            @include media-breakpoint-down(sm) {
                margin: 10px auto;
                max-width: 230px;
            }
        }
    }

    .pre-title {
        font-size: 18px;
        line-height: 2.12;
        letter-spacing: 3px;
        font-family: $font-family-base;
        font-weight: 300;
        margin: 0 0 20px;
        text-transform: uppercase;

        @include media-breakpoint-down(sm) {
            font-size: 14px;
        }

        &::after {
            content: "";
            max-width: 420px;
            width: 100%;
            height: 1px;
            background: #c0c0c0;
            display: block;
            margin: 10px auto;
            opacity: 0.75;

            @include media-breakpoint-down(sm) {
                margin: 10px auto;
                max-width: 230px;
            }
        }
    }

    .second-title {
        font-size: 18px;
        line-height: 2.12;
        letter-spacing: 3px;
        font-family: $font-family-base;
        font-weight: 300;
        margin: 0;
        text-transform: uppercase;

        @include media-breakpoint-down(sm) {
            font-size: 14px;
        }
    }

    .content {
        font-size: 18px;
        font-weight: 300;
        line-height: 1.75;
        letter-spacing: normal;
        text-align: center;
        max-width: 60%;
        margin: 0 auto;

        @include media-breakpoint-down(md) {
            font-size: 16px;
            max-width: 100%;
        }

        @include media-breakpoint-down(sm) {
            font-size: 15px;
            max-width: 100%;
        }
    }

    &.has--content {
        .second-title {
            margin-bottom: 20px;
        }
    }
}

.dark {
    .brick-special-heading {
        .first-title {
            color: $yellow;

            &::after {
                background: $yellow;
            }
        }

        .second-title {
            color: $yellow;
        }

        .content {
            color: #f5f5f5;
        }
    }
}

.purple {
    .brick-special-heading {
        .first-title {
            color: $white;

            &::after {
                background: #c0c0c0;
            }
        }

        .second-title {
            color: $white;
        }

        .content {
            color: $white;
        }
    }
}

.video-direct-link {
    .brick-special-heading {
        .first-title {
            color: $white;

            &::after {
                background: #c0c0c0;
            }
        }

        .second-title {
            color: $white;
        }

        .content {
            color: $white;
        }
    }
}