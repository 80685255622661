.brick-overlay-content-teaser {
    .brick-special-heading {
        .second-title {
            @include media-breakpoint-up(md) {
                margin-bottom: 60px;
            }
        }

        .content {
            &:after {
                content: "";
                max-width: 420px;
                width: 100%;
                height: 1px;
                background: #c0c0c0;
                display: block;
                margin: 60px auto 0;
                opacity: 0.75;


                @include media-breakpoint-down(md) {
                    margin: 10px auto 0;
                    max-width: 230px;
                }
            }
        }
    }
}