.property-map {
    .vue-google-map {
        height: 392px;
    }

    .property-map__counter {
        border: 1px solid #e8c875;
        border-radius: 50%;
        color: $white;
        font-family: $font-family-base;
        font-weight: 500;
        height: 68px;
        left: 50%;
        padding: 15px 0 0;
        position: absolute;
        text-align: center;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 68px;


        &:before {
            background: rgba(232, 200, 117, 0.1);
            border: 2px solid #e8c875;
            border-radius: 50%;
            content: '';
            height: 236px;
            left: -84px;
            position: absolute;
            top: -84px;
            width: 236px;
            z-index: -1;
        }

        &:after {
            background: rgba(232, 200, 117, 0.1);
            border: 2px solid #e8c875;
            border-radius: 50%;
            content: '';
            -webkit-filter: blur(6px);
            filter: blur(6px);
            height: 236px;
            left: -84px;
            position: absolute;
            top: -84px;
            width: 236px;
            z-index: -2;
            transition: ease-in-out 300ms all;
        }

        .property-map__counter-number {
            font-size: 26px;
            line-height: 0.69;
            margin-bottom: 2px;
        }

        .property-map__counter-label {
            font-size: 8px;
            line-height: 2.25;
            margin-bottom: 0;
            text-transform: uppercase;
        }

        &:hover, &:focus {
            &:after {
                filter: blur(15px);
            }
        }
    }

    @include media-breakpoint-up(sm) {
        .vue-google-map {
            height: 584px;
        }
    }
}