$directions: ('top', 'bottom', 'left', 'right');

@each $direction in $directions {
    .no--padding-#{$direction} {
        padding-#{$direction}: 0;
    }
    .no--border-#{$direction} {
        border-#{$direction}: none;
    }
    .no--margin-#{$direction} {
        margin-#{$direction}: 0;
    }

    .m-#{$direction}-gutter {
        margin-#{$direction}: $grid-gutter-width;
    }

}

$spacers: (12, 25, 40, 50, 75, 100, 150, 200, 300);

@each $spacer in $spacers {
    .spacer-#{$spacer} {
        display: block;
        height: #{$spacer}px;
    }
}

.spacer-nav-height {
    height: 70px;
    display: block;

    @include media-breakpoint-up(md) {
        height: 90px;
    }
}


::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-thumb {
    background: #888;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

[data-aos="clip-right"] {
    clip-path: inset(0 100% 0 0);
}

[data-aos="clip-right"].aos-animate {
    clip-path: inset(0 0 0 0);
}