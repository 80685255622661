html, body {
  scroll-behavior: smooth;
}

html {
  height: -webkit-fill-available;
}

svg {
  vertical-align: top !important;
}

.cc-window {
  font-family: $font-family-base;
}

body {
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
}

body:not(.dark, .purple) {
  @include noisyBackground;
}

.country-selector {
  z-index: 9999 !important;
}

.editmode {
  [data-aos] {
    opacity: 1 !important;
    /*CSS transitions*/
    -o-transition-property: none !important;
    -moz-transition-property: none !important;
    -ms-transition-property: none !important;
    -webkit-transition-property: none !important;
    transition-property: none !important;
    /*CSS transforms*/
    -o-transform: none !important;
    -moz-transform: none !important;
    -ms-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;
    /*CSS animations*/
    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
  }
}

.preloader {
  @include noisyBackground();
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: ease-in-out 400ms opacity;
  transition-delay: 1100ms;
  flex-direction: column;

  img {
    height: 80px;
    width: auto;
  }

  .preloader-text {
    margin: 20px 0 0;
    font-size: 16px;
    font-weight: 300;
  }
}

body.is--loaded {
  opacity: 1 !important;
  transition: 0.3s ease-in opacity;

  .preloader {
    opacity: 0;
  }
}

body.loading-animation-done {
  .preloader {
    display: none;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.custom-control {
  padding-left: 2rem;
}

h1, h2, h3, h4 {
  color: #16181e;
  letter-spacing: 3.04px;
  font-family: 'Optima';
}

iframe {
  border: 0 none;
}

.has--transparent-bg {
  background: transparent;
  background-color: transparent;
}


.headline {
  font-size: 35px;
  color: #16181e;
  letter-spacing: 3.04px;
  line-height: 45px;
  font-family: 'Optima';
}

.subline {
  font-family: 'Muller';
  font-size: 20px;
  color: #16181e;
  letter-spacing: 5px;
  line-height: 27px;
}

.text-reg {
  font-family: 'Muller';
  font-size: 18px;
  line-height: 1.67;
  color: #16181e;
  letter-spacing: 0;
}


.form-control {
  font-size: 12px;
  font-weight: 500;
  border: none;
  border-radius: 0;
  letter-spacing: 3.7px;
  height: 50px;
  color: $white;
  position: relative;
  background: $navi;
  @include grainOverlay();
}

.form-control::placeholder {
  color: #b8b8b8;
}

.form-control:focus {
  color: #5a5a5a;
  background-color: #ededed;
  outline: 0;
  box-shadow: none;
  border: none;
}

.form-control:focus::placeholder {
  color: #5a5a5a;
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus,
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: transparent;
  box-shadow: none;
}

.form-check-input {
  position: absolute;
  margin-top: 5px;
  margin-left: -1.25rem;
  width: 17px;
  height: 17px;
  border-radius: 0 !important;
  border: solid 1px #1f3d45;
}

.form-check-label {
  margin-bottom: 0;
  font-size: 14px;
  letter-spacing: normal;
  font-style: italic;
  padding-left: 5px;
}

.custom-checkbox .custom-control-label {
  font-size: 15px;
  font-style: italic;
  margin-right: 25px;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0;
  width: 19px;
  height: 19px;
  border-color: #1f3d45;
  transform: translate(-8px, -2px);
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #ffffff;
  border-color: #1f3d45;
  background-color: #1f3d45;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  font-family: $icons-font-family;
  content: '\e931';
  color: #fff;
  background-image: none;
  font-size: 8px;
  width: 19px;
  height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(-9px, -1px);
}


#modal-secret-login .modal-content {
  width: 315px;
  border: none;
  border-radius: 0;
}

#modal-secret-login h2 {
  font-size: 24px;
  line-height: 1.21;
  letter-spacing: 2.09px;
  text-align: center;
  color: #494948;
  padding: 0 30px;
  margin-top: 28px;
  margin-bottom: 14px;
}

#modal-secret-login .modal-body {
  padding: 16px 30px;
}

#modal-secret-login .form-group {
  margin-bottom: 10px;
}

#modal-secret-login button[type="submit"] {
  height: 36px;
  margin: auto;
  margin-top: 25px;
  display: block;
  margin-bottom: 24px;
  line-height: 20px;
  width: 200px;
  font-size: 16px;
  letter-spacing: 2.4px;
}

#modal-secret-login button[type="submit"]:before {
  height: 36px;
}

#modal-secret-login button[type="submit"]:after {
  height: 44px;
}

#modal-secret-login .bottom {
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 17px;
}

#modal-secret-login .bottom a {
  font-size: 13px;
  color: #494948;
  text-decoration: underline;
  letter-spacing: 0;
}


.hero-slider {
  height: 100vh;
}

.hero-slider .img-fluid {
  max-width: 100%;
  height: 100vh;
  object-fit: cover;
}

.hero-slider .carousel-caption {
  bottom: 96px;

  .scroll-down-text {
    margin-top: 45px;
    position: relative;
    display: inline-block;
  }
}

.hero-slider .carousel-caption:before {
  content: "";
  width: 200px;
  height: 1px;
  background: #fff;
  display: block;
  margin: auto;
  margin-bottom: 35px;
}

.hero-slider .carousel-caption h2 {
  font-family: Optima;
  font-size: 49px;
  line-height: 1.09;
  letter-spacing: 1.63px;
  text-align: center;
  color: $grey;
}

.hero-slider .carousel-caption h2 strong {
  font-family: 'Optima'
}

.carousel-indicators li {
  width: 10px;
  height: 10px;
  border: none;
  border-radius: 100px;
  overflow: hidden;
  margin-left: 5px;
  margin-right: 5px;
}

.carousel-indicators {
  margin-bottom: 50px;
}

.card-text {
  border: none;
}

.card-text h1, .card-text .first-title {
  font-family: $headings-font-family;
  font-size: 35px;
  line-height: 1.23;
  letter-spacing: 3.04px;
  text-align: center;
  color: $dark-grey;
  font-weight: 300;

  &:after {
    content: "";
    max-width: 420px;
    width: 100%;
    height: 1px;
    background: #c0c0c0;
    display: block;
    margin: auto;
    margin-bottom: 20px;
    margin-top: 17px;
  }
}

.card-text h2, {
  font-family: 'Optima';
  font-size: 35px;
  line-height: 1.23;
  letter-spacing: 3.04px;
  text-align: center;
  color: $dark-grey;
}

.card-text h2:after {
  content: "";
  max-width: 420px;
  width: 100%;
  height: 1px;
  background: #c0c0c0;
  display: block;
  margin: auto;
  margin-bottom: 20px;
  margin-top: 17px;
}

.card-text h3 {
  font-family: 'Muller';
  font-size: 16px;
  line-height: 1.69;
  letter-spacing: 4px;
  text-align: center;
  color: $dark-grey;
  margin-bottom: 31px;
}

.card-text h1 + h2:after {
  display: none;
}

.card-text p {
  font-family: 'Muller';
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: center;
  color: $dark-grey;
  max-width: 840px;
  margin: auto;
}

.card-text .card-body {
  padding-top: 84px;
  padding-bottom: 73px;
}

.property-selector {
  max-width: 970px;
  margin-bottom: 50px;
}

.property-selector .topline {
  color: $white;
  display: flex;
  position: relative;
  height: 55px;
  justify-content: space-between;
  padding-right: 55px;
  align-items: center;
  margin-bottom: 25px;
  border-radius: 2px;
  overflow: hidden;

  @include grainOverlay();

  @include media-breakpoint-down(md) {
    margin-bottom: 20px;
  }
}

.property-selector .topline .topline-title {
  font-family: $headings-font-family;
  font-size: 22px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 2px;
  color: #f5f5f5;
  padding: 0px 20px;
  margin-top: 18px;
  font-weight: 300;

  @include media-breakpoint-down(md) {
    font-size: 20px;
    padding: 0 10px;
    margin-top: 18px;
  }
}

.property-selector .topline .line {
  width: 100%;
  height: 1px;
  background-color: #d8d8d8;
}

.property-selector .collapsed > .when-open,
.property-selector .not-collapsed > .when-closed {
  display: none;
}

.property-selector .num {
  font-family: $headings-font-family;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 2px;
  color: #f5f5f5;
  margin-left: 18px;
  margin-right: 11px;
  line-height: 1;
  margin-top: 4px;
}

.property-selector .open-prop {
  position: absolute;
  right: 0;
  top: 0;
  width: 55px;
  height: 55px;
  border-radius: 0;
  background: #365c66;
  border: none;
}

.property-selector .open-prop:active,
.property-selector .open-prop:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.property-selector .open-prop .cli {
  transform: translateY(-5px);
}

.property-selector .nav-tabs .nav-item {
  position: relative;
}

.property-selector .nav-tabs .nav-item:first-child {
  display: none;
}

.property-selector .nav-tabs {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  border: none;
  gap: 20px;
}

.property-selector .nav-tabs .nav-item:last-child {
  margin-right: 0;
}

.property-selector .nav-tabs .nav-item .img-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.property-selector .nav-tabs .nav-item img {
  filter: brightness(0.5);
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.property-selector .nav-tabs .nav-link {
  padding: 0;
  border: 0;
  display: inline-block;
  height: 100%;
  position: relative;
  width: 220px;
  height: 240px;

  img {
    transition: ease-in-out transform 300ms;
  }
}

.property-selector .nav-tabs .nav-link:focus,
.property-selector .nav-tabs .nav-link:active {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.property-selector .nav-tabs .title {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: $font-family-base;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.2;
  color: $grey;
  margin-top: -5px;
  padding: 0 28px;
  letter-spacing: normal;

  @include media-breakpoint-down(sm) {
    font-size: 16px;
  }

  &:before {
    content: "";
    width: calc(100% - 42px);
    height: calc(100% - 41px);
    position: absolute;
    top: 15px;
    left: 21px;
    border: 1px solid #f2f2f2;
    opacity: 0;
    transition: ease-in-out 300ms opacity;

    @include media-breakpoint-down(sm) {
      height: calc(100% - 31px);
    }
  }

  &:after {
    content: "";
    width: calc(100% - 20px);
    height: calc(100% - 61px);
    position: absolute;
    top: 25px;
    left: 10px;
    border: 1px solid #f2f2f2;
    opacity: 0;
    transition: ease-in-out 300ms opacity;

    @include media-breakpoint-down(sm) {
      height: calc(100% - 51px);
    }
  }
}


.property-selector .nav-tabs .nav-link:before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 60px 60px;
  border-color: transparent transparent #f2f2f2 transparent;
  position: absolute;
  right: 10px;
  bottom: 31px;
  z-index: 1;
  opacity: 0;
  transition: opacity 300ms ease-in-out;

  @include media-breakpoint-down(md) {
    bottom: 10px;
  }
}

.property-selector .nav-tabs .nav-link.option-active .img-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background-color: #fff;
}

.property-selector .nav-tabs .nav-link.option-active .img-container img {
  width: 100%;
  height: 100%;
  display: block;
}

.property-selector .nav-tabs .nav-link:hover {
  @include media-breakpoint-up(sm) {
    .img-container {
      img {
        transform: scale(1.2);
      }
    }

    .title {


      &:after, &:before {
        opacity: 0.6;
      }
    }
  }
}

.property-selector .nav-tabs .nav-link.option-active {

  .img-container {
    img {
      transform: scale(1.2);
    }
  }

  .title {
    &:after, &:before {
      opacity: 1;
    }
  }
}

.property-selector .nav-tabs .nav-link .img-container:before {
  content: '';
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-bottom: 20px;
  @include noisyBackground();
  z-index: 1;

  @include media-breakpoint-down(md) {
    padding-bottom: 10px;
  }
}

.property-selector .nav-tabs .nav-link.option-active.has--city-options .img-container:before,
.property-selector .nav-tabs .nav-link.option-active.has--city-options .img-container:after {
  content: '';
  position: absolute;
  bottom: 0;
  width: 50%;
  @include noisyBackground();
  padding-bottom: 20px;

  @include media-breakpoint-down(md) {
    padding-bottom: 10px;
  }
}

.property-selector .nav-tabs .nav-link.option-active.has--city-options .img-container:before {
  @include media-breakpoint-up(sm) {
    right: 175px;
    transform-origin: 100% 100%;
    transform: skewX(54deg);
    z-index: 1;
  }
  @include media-breakpoint-down(sm) {
    width: 100%;
    right: 0;
  }
}

.property-selector .nav-tabs .nav-link.option-active.has--city-options .img-container:after {
  left: 38px;
  transform-origin: 0 100%;
  transform: skewX(-54deg);
  width: 100%;
}


.property-selector.property-selector-immobile .nav-tabs .nav-link.option-active .img-container:before,
.property-selector.property-selector-budget .nav-tabs .nav-link.option-active .img-container:before {
  content: '';
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-bottom: 20px;
  @include noisyBackground();
  z-index: 1;
  left: 0;

  @include media-breakpoint-down(md) {
    padding-bottom: 10px;
  }
}

.property-selector .tab-content .inner {
  height: 65px;
  border-radius: 2px;
  border: solid 1px $dark-grey;
  display: flex;
}

.property-selector .tab-content .title {
  background: $dark-grey;
  font-size: 24px;
  letter-spacing: 0.8px;
  text-align: center;
  color: $grey;
  width: 255px;
  font-family: $headings-font-family;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: center;
}

.property-selector .tab-content .title .option {
  font-size: 14px;
  font-style: italic;
}

.property-selector .location-slider .carousel-caption {
  color: $dark-grey;
  position: relative;
  bottom: unset;
  padding: 0;
  left: 11%;
}

.property-selector .tab-content .location-slider .check {
  width: 21px;
  height: 21px;
  border: solid 1px #494948;
  border-radius: 40px;
  position: absolute;
  top: 20px;
  left: 0;
  cursor: pointer;
}

.property-selector .tab-content .location-slider .check.checked {
  background: #4c4c4b;
  font-family: $icons-font-family;
  color: #fff;
  font-size: 8px;
}

.property-selector .tab-content .location-slider .check.checked:after {
  content: '\e931';
  transform: translate(6px, 3px);
  display: inline-block;
}

.property-selector .location-slider {
  height: 100%;
  width: 100%;

  @include media-breakpoint-down(md) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 180px;
    -webkit-overflow-scrolling: touch;

    .item {
      min-height: unset;
      height: auto;
      margin-bottom: 10px;
      overflow: visible;
    }
  }
}

.property-selector .location-slider .item {
  position: relative;
  padding-left: 32px;
  padding-right: 29px;
  padding-top: 17px;
  margin-left: 10px;
}

.property-selector .location-slider .item .name {
  font-size: 18px;
  text-align: left;
  line-height: 25px;
}

.property-selector .location-slider .item .count {
  font-size: 12px;
  text-align: left;
}

.property-selector .location-slider .carousel,
.property-selector .location-slider .carousel-inner,
.property-selector .location-slider .carousel-item {
  height: 100%;
}

.property-selector .location-slider .carousel-control-prev,
.property-selector .location-slider .carousel-control-next {
  opacity: 1;
  width: 60px;
  background: transparent;
  z-index: 10;
  line-height: 60px;
}

.property-selector .location-slider .carousel-control-prev-icon,
.property-selector .location-slider .carousel-control-next-icon {
  background-image: none;
  font-family: 'Cumlaudeimmobilia';
  color: $dark-grey;
  font-size: 13px;
}

.property-selector .location-slider .carousel-control-prev-icon:after {
  content: '\e92a';
}

.property-selector .location-slider .carousel-control-next-icon:after {
  content: '\e929';
}

.property-selector-container .btn-wrap {
  text-align: center;
}

.property-selector-container .btn-wrap button {
  font-size: 14px;
  letter-spacing: 2.39px;
  padding-top: 10px;
}

.property-selector-container .btn-wrap button span {
  text-decoration: underline;
}


.property-cards {
  max-width: 1350px;
}

.property-cards .property-cards-list {
  display: grid;
  grid-template-areas: ".item";
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin-bottom: 20px;
  grid-auto-columns: 1fr 1fr;
}

.property-cards .item {
  height: 560px;
  position: relative;
}

.property-cards .item > a {
  display: block;
  height: 100%;
  padding-top: 24px;
  padding-left: 50px;
  padding-right: 50px;
  position: relative;
  width: 100%;
  z-index: 1;
}

.property-slider-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.property-slider-container .carousel,
.property-slider-container .carousel-inner,
.property-slider-container .carousel-item {
  height: 100%;
}

.property-slider-container .carousel-item img {
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.property-grid-top {
  max-width: 1350px;
}

.property-grid-top .top-info {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: $dark-grey;
  border-bottom: 1px solid #c0c0c0;
  padding-bottom: 14px;
  align-items: center;
}

.property-grid-top .top-info .subtext {
  color: #8b8b8b;
}

.property-grid-top .top-info .right {
  text-align: right;
}

.property-grid-top .filter {
  text-align: right;
  padding-top: 14px;
  padding-bottom: 25px;
}

.property-grid-top .filter button {
  color: #494948;
  font-size: 14px;
  letter-spacing: 0px;
  text-transform: none;
  padding: 0;
}

.property-grid-top .filter button:before {
  font-family: 'icomoon';
  content: "\e923";
  width: auto;
  height: auto;
  position: relative;
  top: 1px;
  left: unset;
  border: none;
  margin-right: 7px;
}

.property-grid-top .filter button:after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  position: relative;
  height: auto;
  top: 2px;
  left: unset;
  margin-left: 9px;
  width: auto;
}

.search-property .property-cards .btn.show-all {
  display: none;
}

.property-cards {
  @include media-breakpoint-down(sm) {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.property-grid-pagination {
  display: flex;
  justify-content: center;
  position: relative;
  border-top: 1px solid #c0c0c0;
  padding-top: 10px;
  font-size: 14px;
  color: $dark-grey;
  padding: 0;
  padding-top: 22px;
  max-width: 1350px;
  margin: 60px auto 0;
}

.property-grid-pagination a {
  color: inherit;
}

.property-grid-pagination .prev:after {
  content: "";
  width: 1px;
  height: 23px;
  display: none;
  background: $dark-grey;
  position: absolute;
  margin-left: 23px;
  top: 20px;
}

.property-grid-pagination.has--next-page.has--prev-page {
  .prev {
    display: inline-block;
    margin-right: 50px;
  }
}

.property-grid-pagination .count {
  position: absolute;
  right: 0;
  top: 10px;
}


.info-block-full-width {
  @include grainOverlay(transparent);
  position: relative;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .item {
    position: relative;
    @include grainOverlay(transparentize($navi, 0.2), 0);

    .content {
      position: relative;
      z-index: 2;
    }
  }

  .btn {
    font-size: 15px;
  }

  .brick-special-heading {
    margin-bottom: 45px;
  }
}

.info-block-full-width .item {
  padding-top: 150px;
  padding-bottom: 179px;
  max-width: 750px;
  margin: auto;
  position: relative;
  padding-left: 75px;
  padding-right: 75px;
}


.info-block-full-width .text {
  font-size: 17px;
  line-height: 1.74;
  letter-spacing: normal;
  text-align: center;
  color: $grey;
  position: relative;
  text-align: center;
  margin-bottom: 53px;
}

.info-block-full-width .btn.more {
  height: 44px;
  font-size: 15px;
  padding: 5px 43px;
}

.info-block-full-width .btn.more:before {
  height: 44px;
}

.info-block-full-width .btn.more:after {
  height: 52px;
}

.info-block-boxed {
  margin: 0 auto;
  padding-left: 0;
  padding-right: 0;
  position: relative;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.info-block-boxed .item {
  max-width: 100%;
  margin: auto;
  padding: 145px 75px;
  position: relative;

  @include grainOverlay(transparentize($navi, 0.2), 0);

  .content {
    position: relative;
    z-index: 2;
  }

}


.info-block-boxed .title {
  font-family: "Optima";
  font-size: 34px;
  line-height: 1.29;
  letter-spacing: 2.96px;
  text-align: center;
  color: $white;
  position: relative;
  text-align: center;
  border-bottom: 1px solid;
  max-width: 460px;
  margin: auto;
  padding-bottom: 19px;
  margin-bottom: 24px;
}

.info-block-boxed .subtitle {
  font-family: 'Muller';
  font-size: 16px;
  line-height: 1.69;
  letter-spacing: 4px;
  text-align: center;
  color: #ffffff;
  position: relative;
  text-align: center;
  margin-bottom: 54px;
}

.info-block-boxed .text {
  font-size: 17px;
  line-height: 1.74;
  letter-spacing: normal;
  text-align: center;
  color: $grey;
  position: relative;
  text-align: center;
  max-width: 820px;
  margin: auto;
  margin-bottom: 30px;
}

.info-block-boxed .text:last-child {
  padding-bottom: 20px;
  position: relative;
}

.info-block-boxed .text:last-child:after {
  content: "";
  max-width: 460px;
  width: 100%;
  height: 1px;
  background: $white;
  margin: auto;
  display: block;
  transform: translateY(65px);
}

body[data-aos-duration='4000'] [data-aos], [data-aos-duration='4000'] {
  transition-duration: 4000ms;
}

body[data-aos-duration='4500'] [data-aos], [data-aos-duration='4500'] {
  transition-duration: 4500ms;
}

.text-video {
  position: relative;
  @include grainOverlay($navi, 0);

  @include media-breakpoint-down(md) {
    padding-top: 40px;
  }

  .embed-responsive {
    position: relative;
    z-index: 1;
  }

  .brick-special-heading {
    .content {
      max-width: unset;

      p:last-child {
        margin: 0;
      }
    }
  }
}

.text-video .text-container {
  z-index: 1;
  position: relative;
}

.text-video {
  .brick-special-heading {
    .first-title {
      max-width: unset;
    }
  }
}

.text-video .col-video {
  @include media-breakpoint-down(sm) {
    padding: 0;
  }
}

.benefits {
  background-size: cover;
  background-position: center center;
  max-width: 1320px;
  padding: 0;
}

.benefits .benefits-slider {
  height: 560px;
  background: rgba(0, 0, 0, 0.5) !important;
  position: relative;

  .carousel-control-prev,
  .carousel-control-next {
    display: block;
    font-family: $icons-font-family;
    background-image: none;
    font-size: 25px;
    z-index: 11;
    width: 40px;
    height: 35px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    transform: translateY(-50%);
    color: white;

    @include media-breakpoint-down(sm) {
      top: unset;
      bottom: 26px;
    }
  }

  .carousel-control-prev {
    left: 40px;
  }

  .carousel-control-next {
    right: 40px;
  }

  .carousel-control-prev-icon {
    background-image: none;

    &:after {
      font-size: 30px;
      content: '\e92a';
      font-family: $icons-font-family;
    }
  }

  .carousel-control-next-icon {
    background-image: none;

    &:after {
      font-size: 30px;
      content: '\e929';
      font-family: $icons-font-family;
    }

  }


}

.benefits .benefits-slider .carousel-inner,
.benefits .benefits-slider .carousel-item {
  height: 100%;
}

.benefits .benefits-slider .carousel-item {
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
  }

  @include grainOverlay(transparent, 2);

  &:before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparentize(black, 0.4);
    z-index: 1;
  }
}

.benefits .carousel-caption {
  bottom: unset;
  top: 115px;
  max-width: 1035px;
  width: 100%;
  left: 50%;
  right: unset;
  transform: translateX(-50%);
  border-right: 1px solid;
  border-left: 1px solid;
  z-index: 3;
}

.benefits .carousel-caption .icon {
  font-size: 65px;
  margin-bottom: 15px;

  @include media-breakpoint-down(sm) {
    font-size: 55px;
  }
}

.benefits .carousel-caption .title {
  font-family: $headings-font-family;
  font-weight: 300;
  font-size: 28px;
  line-height: 1.58;
  letter-spacing: 2.48px;
  text-align: center;
  color: #f2f2f2;
  margin-bottom: 21px;

  @include media-breakpoint-down(sm) {
    font-size: 24px;
    margin-bottom: 15px;
  }
}

.benefits .carousel-caption .text {
  font-size: 16px;
  line-height: 1.79;
  letter-spacing: normal;
  text-align: center;
  color: #f2f2f2;
  max-width: 670px;
  margin: auto;
}

.benefits .carousel-indicators {
  margin-bottom: 58px;
}

.benefits .carousel-control-prev,
.benefits .carousel-control-next {
  display: none;
}

.partners {
  max-width: 1220px;
  margin: 0 auto;
}

.partners .partners-slider {
  height: 267px;
  display: none;

  @include media-breakpoint-up(sm) {
    display: block;
  }

  &.partners-slider--sm {
    height: auto;
    display: block;

    @include media-breakpoint-up(sm) {
      display: none;
    }
  }
}

.partners .carousel-caption,
.partners .carousel-inner {
  height: 100%;
}

.partners .carousel-caption {
  position: relative;
  right: unset;
  left: unset;
}

.partners .logo-container {
  display: grid;
  grid-template-areas: "img";
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  max-width: 1200px;
  margin: auto;
  grid-row-gap: 52px;
}

.partners .logo-container img {
  display: inline-block;
  margin: auto;
  height: 70px;
  width: auto;
  opacity: 0.8;
  transition: ease 200ms all;

  &:hover {
    opacity: 1;
  }

  @include media-breakpoint-down(sm) {
    width: 42%;
    height: auto;
  }
}

.partners .carousel-indicators {
  margin-bottom: 0;
}

.partners .carousel-indicators li {
  background-color: #2c2b2f;
}

.partners .carousel-indicators li.active {
  background-color: #1f3d45;
}

.partners .carousel-control-prev-icon,
.partners .carousel-control-next-icon {
  font-family: 'Cumlaudeimmobilia';
  color: #000;
  background-image: none;
  font-size: 25px;
  z-index: 11;
  position: relative;
  width: 50px;
  height: 35px;
}

.partners .carousel-control-prev,
.partners .carousel-control-next {
  z-index: 11;
  transform: translateY(-42px);
  width: 50px;
}

.partners .carousel-control-next-icon:after {
  content: '\e929';
}

.partners .carousel-control-prev-icon:after {
  content: '\e92a';
}

.bottom-logo {
  background-color: #f2f2f2;
  padding: 84px 0;

  @include media-breakpoint-down(md) {
    padding: 72px 0;
  }
}

.bottom-logo img {
  width: 270px;
  margin: 0 auto;
  display: block;
  height: auto;

  @include media-breakpoint-down(md) {
    width: 190px;
  }

  &.logo-white {
    display: none;
  }
}

.footer .footer-bottom {
  background: $dark-grey;
  color: #fff;
  font-size: 12px;
  line-height: 3.13;
  letter-spacing: 1px;
  text-align: center;
  color: #f2f2f2;
  text-transform: uppercase;
  padding-top: 7px;
  padding-bottom: 10px;
}

.search-property-form {
  max-width: 625px;
  margin: auto;
}

.search-property-form .form-control {
  height: 59px;
  border-radius: 2px;
  border: solid 1px #494948;
  background: transparent;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  padding-left: 26px;
  transition: ease-in-out 200ms all;
}

.search-property-form .submit {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  background: no-repeat;
  width: 59px;
  height: 59px;
  transition: ease-in-out 200ms all;
}

.search-property-form .submit:hover {
  opacity: 0.6;
}

.search-property-form .submit .cli-search {
  font-size: 17px;
  transform: translate(-3px, 0px);
  color: #494948;
}

@media (max-width: 991px) {
  .navbar-right {
    flex-direction: row;
  }

  .property-selector .nav-tabs {
    display: grid;
    border: none;
    grid-template-areas: "li";
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 0;
  }
  .property-selector .nav-tabs .nav-item {
    margin: 0;
    height: 0;
    padding-top: calc(100% + 20px);
    width: 100%;
  }
  .property-selector .nav-tabs .nav-link {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
  }
  .property-selector .tab-content .inner {
    display: block;
    height: 240px;
    overflow: hidden;
  }
  .property-selector .tab-content .title {
    width: 100%;
    font-size: 20px;
    padding-bottom: 15px;
  }
  .property-selector .tab-content .title .option {
    display: block;
    font-size: 12px;
    margin-top: 2px;
  }
  .property-selector .location-slider .item {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    width: 100%;
  }
  .property-selector .location-slider .carousel-control-prev, .property-selector .location-slider .carousel-control-next {
    background: transparent;
    width: 38px;
    top: -66px;
  }
  .property-selector .location-slider .item .name {
    text-align: left;
  }
  .property-selector .nav-tabs .nav-link.active .img-container:after {
    display: none;
  }
  .property-selector .nav-tabs .nav-link.active .img-container:before {
    right: -30px;
    width: 125%;
  }
  .property-selector.property-selector-immobile .nav-tabs .nav-link.active .img-container:before, .property-selector.property-selector-budget .nav-tabs .nav-link.active .img-container:before {
    left: unset;
    right: -30px;
    width: 125%;
  }


  .property-cards .card-title {
    font-size: 30px;
  }
  .property-cards .short-info {
    font-size: 12px;
  }
  .property-cards .item .carousel-control-prev, .property-cards .item .carousel-control-next {
    width: 22%;
  }
  .info-block-full-width .item {
    max-width: 600px;
  }
  .text-video > .container {
    flex-direction: column;
  }
  .benefits .carousel-caption {
    max-width: 600px;
    padding: 15px;
  }
  .partners {
    max-width: 600px;
  }
  .partners .carousel-control-next {
    right: -45px;
  }
  .partners .carousel-control-prev {
    left: -45px;
  }

}

@media (max-width: 767px) {


  .hero-slider .carousel-caption h2 {
    font-size: 35px;
  }
  .hero-slider .carousel-caption:before {
    margin-bottom: 15px;
  }
  .hero-slider .carousel-caption {
    bottom: 105px;
  }
  .card-text h2 {
    font-size: 24px;
    line-height: 1.33;
    letter-spacing: 2.09px;
  }
  .card-text h2:after {
    max-width: 270px;
  }
  .card-text h3 {
    font-size: 14px;
    line-height: 2.12;
    letter-spacing: 3px;
  }
  .card-text p {
    font-size: 16px;
    font-weight: 300;
    line-height: 26px;
    letter-spacing: normal;
    text-align: center;
  }
  .property-selector .topline {
    height: 47px;
  }
  .property-selector .topline {
    font-size: 21px;
  }
  .property-cards .item .card-top .cli-heart-outline {
    font-size: 22px;
  }
  .property-cards .card-bottom {
    max-width: 100%;
  }
  .property-cards .card-bottom .inner {
    max-width: 250px;
    margin: auto;
  }
  .property-selector .open-prop {
    width: 47px;
    height: 47px;
  }
  .property-cards {
    padding: 0;
  }
  .property-cards .item .carousel-control-prev-icon, .property-cards .item .carousel-control-next-icon {
    transform: translateY(190px);
  }
  .property-cards .item:after,
  .property-cards .item:before {
    height: 100px;
  }
  .property-cards .item .card-top .cli-heart-outline:after {
    height: 113px;
  }
  .property-cards .item .top-label:after {
    height: 128px;
  }
  .property-cards .short-info {
    font-size: 14px;
  }
  .property-cards .card-title {
    font-size: 30px;
    margin: auto;
    margin-bottom: 15px;
  }
  .property-cards .price {
    transform: translateY(0px);
  }
  .property-cards .item .top-label {
    font-size: 14px;
    min-width: 155px;
  }
  .property-cards .property-cards-list {
    display: block;
  }
  .property-cards .item {
    height: 420px;
    margin-bottom: 20px;
  }
  .info-block-full-width .title,
  .info-block-boxed .title {
    font-size: 28px;
    line-height: 1.4;
  }
  .info-block-full-width .subtitle,
  .info-block-boxed .subtitle {
    font-size: 14px;
    line-height: 1.57;
    letter-spacing: 3px;
  }
  .info-block-full-width .text,
  .info-block-boxed .text {
    font-size: 16px;
    font-weight: 300;
    line-height: 26px;
  }
  .info-block-full-width .btn.more {
    font-size: 14px;
    padding: 5px 29px;
    letter-spacing: 2.4px;
  }
  .info-block-full-width .item {
    max-width: 86%;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 50px;
    padding-bottom: 79px;
  }
  .info-block-boxed .item {
    padding-top: 50px;
    padding-bottom: 68px;
    padding-left: 25px;
    padding-right: 25px;
  }
  .text-video .text-container {
    padding: 40px 25px;
    text-align: center;
  }
  .text-video .text-container .title {
    margin: auto;
    text-align: center;
    max-width: 240px;
    font-size: 23px;
    line-height: 1.3;
    letter-spacing: 2px;
  }
  .text-video .text-container .subtitle {
    text-align: center;
    margin-top: 20px;
    font-size: 14px;
    line-height: 1.29;
    letter-spacing: 3px;
  }
  .text-video .text-container .text {
    padding-left: 0;
    text-align: center;
    font-size: 16px;
    line-height: 26px;
  }
  .form1 {
    padding-left: 22px;
    padding-right: 22px;
  }
  .form1 .inputs {
    max-width: 100%;
  }
  .form1 .tel {
    height: 50px;
  }
  .form1 .frame {
    border: none;
    box-shadow: none;
    padding: 0;
  }
  .form1 .text-info {
    display: none;
  }

  .benefits .carousel-caption {
    top: 30px;
    border: none;
  }
  .partners .logo-container {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr;
  }
  .partners .carousel-control-prev {
    left: 0;
  }
  .partners .carousel-control-next {
    right: 0;
  }
  .partners .carousel-indicators {
    margin-bottom: -30px;
  }
  .form2 form .inner {
    padding-top: 47px;
    padding-left: 35px;
    padding-right: 35px;
    padding-bottom: 61px;
  }
  .form2 form .title {
    font-size: 23px;
  }
  .form2 form .subtitle {
    font-size: 14px;
  }
  .form2 form .text {
    font-size: 16px;
  }
  .form2 form .name, .form2 form .email {
    height: 50px;
  }
  .form2 form .custom-checkbox .custom-control-label {
    font-size: 13px;
  }
  .form2 form button[type="submit"] {
    font-size: 13px;
  }
  .bottom-logo img {
    width: 157px;
  }
  .property-selector,
  .search-property-form {
    padding: 0 22px;
  }
  .property-selector {
    margin-bottom: 25px;
  }
  .search-property .card-text .card-body {
    padding-bottom: 0;
  }
  .search-property-form .form-group {
    margin-bottom: 25px;
  }
  .search-property-form input[type="search"] {
    height: 47px;
  }
  .search-property-form .submit {
    height: 100%;
    width: 47px;
  }
  .property-grid-pagination {
    padding-top: 45px;
    margin: 0 auto;
  }
  .property-grid-pagination .prev:after {
    top: 45px;
  }

}

.toolbox-element.wysiwyg {
  font-size: 18px;
  font-weight: 300;
  line-height: 1.75;
  letter-spacing: normal;
  max-width: 60%;
  margin: 0 auto;

  @include media-breakpoint-down(md) {
    max-width: unset;
  }
}

.modal-backdrop {
  opacity: 0.75;
  background: black;
}

.cli-linkedin:before {
  margin-top: -4px;
  display: block;
}

.badge-catalog {
  position: absolute;
  top: 70px;
  right: 10px;
  width: 75px;
  transform: rotate(10deg);
  z-index: $zindex-fixed - 20;

  @include media-breakpoint-up(md) {
    width: 150px;
    top: 100px;
    right: 30px;
  }
}










