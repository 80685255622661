#baguetteBox-overlay {
  background: transparentize($navi, 0.2) !important;

  #baguetteBox-slider {
    @include media-breakpoint-up(md) {
      padding: 50px 0;
    }
  }

  .baguetteBox-button {
    background: none !important;
  }
}