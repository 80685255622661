@font-face {
    font-family: 'Muller';
    src: url('subset-Muller-LightItalic.woff2') format('woff2'),
        url('subset-Muller-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Muller';
    src: url('subset-Muller-BoldItalic.woff2') format('woff2'),
        url('subset-Muller-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Muller';
    src: url('subset-Muller-Light.woff2') format('woff2'),
        url('subset-Muller-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muller';
    src: url('subset-Muller-Bold.woff2') format('woff2'),
        url('subset-Muller-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muller';
    src: url('subset-Muller-Medium.woff2') format('woff2'),
        url('subset-Muller-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muller';
    src: url('subset-Muller-MediumItalic.woff2') format('woff2'),
        url('subset-Muller-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Muller';
    src: url('subset-Muller-RegularItalic.woff2') format('woff2'),
        url('subset-Muller-RegularItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Muller';
    src: url('subset-Muller-Regular.woff2') format('woff2'),
        url('subset-Muller-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Optima';
    src: url('subset-OptimaLTPro-BoldItalic.woff2') format('woff2'),
        url('subset-OptimaLTPro-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Optima';
    src: url('subset-OptimaLTPro-DemiBoldItalic.woff2') format('woff2'),
        url('subset-OptimaLTPro-DemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Optima';
    src: url('subset-OptimaLTPro-Black.woff2') format('woff2'),
        url('subset-OptimaLTPro-Black.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Optima';
    src: url('subset-OptimaLTPro-Bold.woff2') format('woff2'),
        url('subset-OptimaLTPro-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Optima';
    src: url('subset-OptimaLTPro-Medium.woff2') format('woff2'),
        url('subset-OptimaLTPro-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Optima';
    src: url('subset-OptimaLTPro-MediumItalic.woff2') format('woff2'),
        url('subset-OptimaLTPro-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Optima';
    src: url('subset-OptimaLTPro-Roman.woff2') format('woff2'),
        url('subset-OptimaLTPro-Roman.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Optima';
    src: url('subset-OptimaLTPro-BlackItalic.woff2') format('woff2'),
        url('subset-OptimaLTPro-BlackItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Optima';
    src: url('subset-OptimaLTPro-DemiBold.woff2') format('woff2'),
        url('subset-OptimaLTPro-DemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Optima XBlack';
    src: url('subset-OptimaLTPro-ExtraBlackIta.woff2') format('woff2'),
        url('subset-OptimaLTPro-ExtraBlackIta.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Optima XBlack';
    src: url('subset-OptimaLTPro-ExtraBlack.woff2') format('woff2'),
        url('subset-OptimaLTPro-ExtraBlack.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Optima';
    src: url('subset-OptimaLTPro-Italic.woff2') format('woff2'),
        url('subset-OptimaLTPro-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

