button {
  outline: none !important;

  &:focus, &:hover, &:active {
    outline: none !important;
  }
}

.btn {
  font-size: 15px;
  transition: background .25s ease-out, color .25s ease-out;

  &.btn-primary {
    font-family: $headings-font-family;
    border: solid 1px $navi;
    background: transparent;
    border-radius: 0;
    color: $navi;
    text-transform: uppercase;
    height: 41px;
    letter-spacing: 3.5px;
    align-items: center;
    padding: 8px 19px;
    position: relative;
    z-index: 1;
    outline: none !important;
    transform: translateY(5px);
    margin-bottom: 9px;

    &:before {
      content: "";
      display: inline-block;
      height: 41px;
      position: absolute;
      width: calc(100% - 5.5px);
      border: solid 1px #1f3d45;
      top: -1px;
      left: 3px;
      z-index: -1;
      transition: all .2s ease-in-out;
    }

    &:after {
      content: "";
      display: inline-block;
      height: 49px;
      position: absolute;
      width: calc(100% - 5.5px);
      border: solid 1px #1f3d45;
      top: -5px;
      left: 3px;
      z-index: -1;
    }

    &:hover, &:focus, &.is--active {
      background: transparent;
      border: solid 0.8px #1f3d45;
      color: #fff !important;

      &:before {
        background: $navi;
      }
    }


  }

  &.btn-secondary {
    font-family: $headings-font-family;
    border: solid 1px #fff;
    background: transparent;
    border-radius: 0;
    color: #fff;
    text-transform: uppercase;
    height: 41px;
    letter-spacing: 3.5px;
    align-items: center;
    padding: 8px 19px;
    position: relative;
    z-index: 1;
    outline: none !important;
    transform: translateY(5px);
    margin-bottom: 9px;

    &:hover {
      background: transparent;
      border: solid 0.8px #fff;
      color: #1f3d45 !important;
    }

    &:before {
      content: "";
      display: inline-block;
      height: 41px;
      position: absolute;
      width: calc(100% - 5.5px);
      border: solid 0.8px #fff;
      top: -1px;
      left: 3px;
      z-index: -1;
    }

    &:after {
      content: "";
      display: inline-block;
      height: 49px;
      position: absolute;
      width: calc(100% - 5.5px);
      border: solid 0.8px #fff;
      top: -5px;
      left: 3px;
      z-index: -1;
    }

    &:before {
      content: "";
      display: inline-block;
      height: 41px;
      position: absolute;
      width: calc(100% - 5.5px);
      border: solid 1px white;
      top: -1px;
      left: 3px;
      z-index: -1;
      transition: all .2s ease-in-out;
    }

    &:after {
      content: "";
      display: inline-block;
      height: 49px;
      position: absolute;
      width: calc(100% - 5.5px);
      border: solid 1px white;
      top: -5px;
      left: 3px;
      z-index: -1;
    }

    &:hover, &:focus, &.is--active {
      background: transparent;
      border: solid 0.8px white;
      color: $navi !important;

      &:before {
        opacity: 1;
        background: white;
      }
    }
  }

  &.btn-white-bold {
    background: white;
    color: $primary;
    letter-spacing: 3.5px;
    text-transform: uppercase;
    font-weight: bold;
    border-radius: 0;
    font-family: $headings-font-family;
    padding: 12px 14px 7px;
    font-size: 18px;
  }

  &:hover, &:focus {
    box-shadow: none;
  }
}
