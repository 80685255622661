
@import "../../scss/common/mixins";
@import "../../scss/common/variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.inquiry-form {
  max-width: 820px;
  margin: 0 auto;

  .frame {
    width: 100%;
    border: solid 1px #1f3d45;
    padding: 56px 80px;
    @include noisyBackground;
    z-index: 20;

    @include media-breakpoint-down(md) {
      padding: 30px 25px;
    }
  }

  .inquiry-form__container {
    position: relative;

    .custom-checkbox {
      .custom-control-label {
        font-weight: normal;
        font-style: normal;
        letter-spacing: 3.7px;
        text-transform: uppercase;

        &:before {
          border-color: $navi;
          background: $navi;
        }
      }

      .custom-control-input:checked ~ .custom-control-label::before {
        border-color: $navi;
        background: $navi;
      }

      .custom-control-input:checked ~ .custom-control-label::after {
        transform: translate(-6px, -1px);
      }

    }

    .required-fields-notice {
      font-size: 14px;
      font-style: italic;
      color: #b8b8b8;
    }

    .input-error {
      font-size: 12px;
      font-weight: bold;
      color: $navi;
      margin-bottom: 3px;
    }

    .checkboxes {
      display: flex;
      justify-content: center;
      margin-bottom: 15px;

      .has--border-right {
        border-right: 1px solid #aaa;
        margin-right: 30px;
      }

      .has--errors {
        .custom-control-label {
          &:before {
            border-color: $navi;
          }
        }
      }
    }

    .form-control {
      font-size: 16px;
      padding: 15px 30px;
      margin-bottom: 15px;
      font-weight: normal;
      color: $white;
      position: relative;
      background: $navi;
      @include grainOverlay();

      ::placeholder {
        color: #A9B0AF;
      }

      &.has--errors {
        border: 1px solid $danger;
      }

      @include media-breakpoint-down(sm) {
        padding: 15px;
      }
    }

    input[type="text"], input[type="email"], input[type="tel"], select {
      height: 65px;
    }

    .bottom-check {
      margin-top: 20px;
      padding: 0 0 0 34px;

      .custom-control-label {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: italic;
        line-height: 22px;
        letter-spacing: normal;
        margin-right: 0;
        color: #494948;
        text-transform: none;
      }

      a {
        color: inherit;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }

    }

  }

  .text-info {
    text-align: center;

    .title {
      font-size: 35px;
      line-height: 1.29;
      letter-spacing: 3.04px;
      text-align: center;
      color: #494948;
      border-bottom: 1px solid;
      padding-bottom: 7px;
      max-width: 420px;
      margin: auto;
      margin-bottom: 27px;
      text-transform: uppercase;

      @include media-breakpoint-down(md) {
        font-size: 26px;
        margin-bottom: 10px;
      }
    }

    .text {
      max-width: 550px;
      margin: auto;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.75;
      letter-spacing: normal;
      text-align: center;
      color: #494948;
      margin-bottom: 31px;
    }
  }

  .inputs {
    max-width: 340px;
    margin: auto;
  }

  button {
    min-width: 276px;

    @include media-breakpoint-down(sm) {
      min-width: 268px;
    }
  }
}

.inquiry-cta {
  margin-top: 50px;

  .cta-item {
    background: #ededed;
    padding: 30px 50px 25px;
    max-width: 820px;
    margin: 0 auto;

    .cta-item__inner {
      width: 100%;
    }

    @include media-breakpoint-down(md) {
      padding: 20px;
    }

    .brick-special-heading {
      .first-title {
        max-width: unset;
        margin: 0;
        font-size: 26px;

        @include media-breakpoint-down(md) {
          font-size: 22px;
        }

        @include media-breakpoint-up(md) {
          &:after {
            margin-left: 0;
          }
        }

      }

      .second-title {
        font-size: 18px;

        @include media-breakpoint-down(md) {
          font-size: 16px;
        }
      }
    }
  }
}

