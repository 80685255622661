
@import "../../../scss/common/mixins";
@import "../../../scss/common/variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

#modal-secret-request-access {
    .content {
        @include media-breakpoint-down(sm) {
            font-size: 14px;
        }
    }

    .custom-checkbox {
        .custom-control-label {
            font-size: 12px;
        }
    }
}
