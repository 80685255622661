.brick-media-download {
    .download-item {
        .download-item__icon {
            width: 100%;
            height: 380px;
            background: $navi;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            font-size: 80px;
            transition: ease-in-out 300ms all;

            img {
                width: 60%;
                height: auto;
                display: block;
            }

            @include media-breakpoint-down(md) {
                height: 260px;
            }
        }

        .download-item__label {
            text-align: center;
            margin-top: 15px;
            margin-bottom: 0;
            font-size: 18px;

            @include media-breakpoint-down(sm) {
                margin-bottom: 25px;
            }
        }

        &:hover, &:focus {
            .download-item__icon {
                background: $purple;
            }
        }
    }
}