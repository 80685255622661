.property-cards {
    .item {
        position: relative;
        overflow: hidden;

        .item-overlay {
            position: absolute;
            bottom: 0;
            left: 0;
            height: 50%;
            width: 100%;
            @include gradient-y(transparentize(black, 0.99), transparentize(black, 0.2));
            z-index: 2;
        }

        .property-slider-container {
            z-index: 1;

            .carousel-inner {
                .img-fluid {
                    transform: scale(1.00);
                    transition: 1s ease-in-out transform;
                }

                .carousel-item {
                    @include grainOverlay(transparent, 2);

                    .carousel-caption {
                        width: 100%;
                        height: 100%;
                        left: 0;
                        top: 0;
                        padding: 0;
                        margin: 0;
                    }

                    img {
                        position: relative;
                        z-index: 1;
                    }
                }
            }
        }

        .item-badge {
            position: absolute;
            right: 50px;
            top: 50px;
            z-index: 5;

            img {
                width: 90px;
                height: auto;
                filter: drop-shadow(2px 4px -1px rgba(0, 0, 0, 0.2));
            }
        }

        .item-content-wrapper {
            z-index: 2;
            position: relative;
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
        }

        // lines
        &:before {
            content: "";
            width: 1px;
            height: 195px;
            background: transparentize(white, 0.5);
            position: absolute;
            bottom: 88px;
            right: 33px;
            z-index: 2;
        }

        &:after {
            content: "";
            width: 1px;
            height: 195px;
            background: transparentize(white, 0.5);
            position: absolute;
            bottom: 88px;
            left: 33px;
            z-index: 1;
        }

        .card-top,
        .new-bage {
            position: relative;
            z-index: 2;
        }

        .property-number {
            color: white;
            text-shadow: 1px 1px 2px rgba(150, 150, 150, 1);
            letter-spacing: 0.5px;
            margin-bottom: 0;
            margin-left: 10px;
        }

        .top-label {
            font-size: 16px;
            color: #f5f5f5;
            min-width: 180px;
            position: relative;
            text-transform: uppercase;
            text-shadow: 1px 1px 2px rgba(150, 150, 150, 1);

            &:after {
                content: "";
                width: 1px;
                height: 185px;
                background: transparentize(white, 0.5);
                position: absolute;
                top: 30px;
                left: 1px;
            }
        }

        .card-top {
            display: flex;
            left: 32px;
            right: 32px;
            top: 24px;
            position: absolute;
            justify-content: space-between;

            .line {
                width: 100%;
                height: 1px;
                background: transparentize(white, 0.5);
                display: inline-block;
                transform: translateY(12px);
            }

            .bookmark-icon {
                color: #f5f5f5;
                font-size: 25px;
                transform: translate(5px, 5px);
                margin-left: 5px;
                position: relative;
                cursor: pointer;
                text-shadow: 1px 1px 2px rgba(150, 150, 150, 1);

                &:after {
                    content: "";
                    width: 1px;
                    height: 179px;
                    background: transparentize(white, 0.5);
                    position: absolute;
                    top: 32px;
                    right: 1px;
                }
            }
        }

        .carousel-control-prev,
        .carousel-control-next {
            width: 12%;
            display: block;
            opacity: 1;
            z-index: 10;
        }

        .carousel-control-prev-icon {
            @include media-breakpoint-up(sm) {
                left: -12px !important;
                position: relative;
            }
        }

        .carousel-control-next-icon {
            right: -12px !important;
            position: relative;
        }

        .carousel-control-prev-icon,
        .carousel-control-next-icon {
            font-family: $icons-font-family;
            background: none;
            transform: translateY(246px);
        }

        .carousel-control-prev-icon:after {
            content: '\e937';
        }

        .carousel-control-next-icon:after {
            content: '\e936';
        }

        .card-bottom {
            position: absolute;
            bottom: 45px;
            max-width: 500px;
            left: 50%;
            width: 75%;
            transform: translateX(-50%);

            .card-title {
                font-family: $headings-font-family;
                font-size: 35px;
                letter-spacing: 3.04px;
                text-align: center;
                color: $grey;
                border-top: 1px solid transparentize(white, 0.5);;
                border-bottom: 1px solid transparentize(white, 0.5);;
                padding: 12px 0 8px;
                text-transform: uppercase;
                line-height: 1;
            }

            .bottom-label {
                font-size: 13px;
                font-weight: 500;
                line-height: 1.92;
                letter-spacing: 0.6px;
                color: #f5f5f5;
                text-transform: uppercase;
                background: $navi;
                display: inline-block;
                padding: 0 5px;
                margin-bottom: 20px;
                box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14);

                @include media-breakpoint-down(sm) {
                    margin-bottom: 5px;
                    font-size: 11px;
                }
            }

            .short-info {
                font-size: 17px;
                line-height: 2.21;
                letter-spacing: normal;
                color: #cdcdcd;
                display: flex;
                justify-content: space-between;
                transform: translateY(1px);
            }

            .location .cli {
                @include media-breakpoint-up(md) {
                    font-size: 21px;
                    margin-right: 3px;
                }
            }

            .price {
                @include media-breakpoint-up(md) {
                    transform: translateY(3px);

                    .cli {
                        font-size: 21px;
                    }
                }
            }

        }

        &:hover {
            .property-slider-container {
                .carousel-inner {
                    .img-fluid {
                        transform: scale(1.2);
                    }
                }
            }
        }

        @include media-breakpoint-down(lg) {
            height: 500px;

            > a {
                padding-right: 70px;
                padding-left: 70px;
            }

            &:after,
            &:before {
                height: 130px;
            }

            .card-bottom {
                max-width: 100%;
                width: 90%;
                left: 5%;
                transform: none;
                bottom: 26px;

                .card-title {
                    font-size: 28px;
                }

                .short-info {
                    display: block;
                    text-align: center;
                    font-size: 14px;
                    line-height: 1.3;

                    .location {
                        display: block;
                        width: 100%;
                        margin-bottom: 5px;
                        line-height: 1.6;
                    }

                    .price {
                        margin-left: 10px;
                    }
                }
            }
        }

        @include media-breakpoint-down(sm) {
            height: 420px;
            margin-bottom: 20px;

            > a {
                padding-left: 50px;
                padding-right: 50px;
            }

            .item-badge {
                right: 45px;
                top: 50px;

                img {
                    width: 70px;
                }
            }

            .card-top .cli-heart-outline {
                font-size: 22px;
            }

            .card-bottom {
                max-width: 100%;
                width: 90%;
                left: 5%;
                transform: none;
                bottom: 26px;

                .inner {
                    max-width: 250px;
                    margin: auto;
                }

                .card-title {
                    font-size: 22px;
                }
            }

            .property-selector .open-prop {
                width: 47px;
                height: 47px;
            }

            .carousel-control-prev-icon, .carousel-control-next-icon {
                transform: translateY(190px);
            }

            &:after,
            &:before {
                height: 100px;
            }
            .card-top .cli-heart-outline:after {
                height: 113px;
            }
            .top-label:after {
                height: 128px;
            }


            .card-title {
                font-size: 30px;
                margin: 0 auto 15px;
            }

            .price {
                transform: translateY(0px);
            }

            .top-label {
                font-size: 14px;
                min-width: 155px;
            }
        }

        &.is--new-development {
            &:before, &:after {
                display: none;
            }

            .card-bottom {
                .short-info {
                    justify-content: center;
                }
            }
        }
    }

    .property-item-placeholder {
        background-size: cover;
        background-position: center center;
        height: 560px;
        width: 100%;
        position: relative;

        @include media-breakpoint-down(sm) {
            height: 420px;
        }

        .item-placeholder__overlay {
            background: transparentize($navi, 0.3);
            width: 80%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 10%;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 0 40px;

            .title {
                color: white;
                font-size: 35px;
                font-family: $headings-font-family;
                letter-spacing: 3.04px;
                text-transform: uppercase;
                margin-bottom: 20px;
                line-height: 1.2;

                @include media-breakpoint-down(sm) {
                    font-size: 26px;
                    margin-bottom: 10px;
                }
            }

            .content {
                color: white;
                font-size: 16px;
                margin-bottom: 20px;

                @include media-breakpoint-down(sm) {
                    font-size: 14px;
                    margin-bottom: 10px;
                }
            }

            .button-divider {
                display: block;
                color: white;
                font-size: 12px;
                margin: 15px auto;
                text-transform: uppercase;
                position: relative;
                letter-spacing: 1px;

                &:before, &:after {
                    content: '';
                    height: 1px;
                    width: 80px;
                    background: transparentize(white, 0.5);
                    position: absolute;
                    top: 50%;
                    margin-top: -1px;
                }

                &:before {
                    left: -85px;
                }

                &:after {
                    right: -85px;
                }
            }

            .btn {
                @include media-breakpoint-down(sm) {
                    font-size: 12px;
                }
            }
        }
    }
}
