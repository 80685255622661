.dropdown {
  &:not(.no-custom-styling) {
    .dropdown-menu {
      padding: 0;
      margin: 0;
      @include noisyBackground;
      border: 1px solid #ccc;
      border-radius: 0;


      .dropdown-item {
        font-size: 14px;
        padding: 5px 8px;
      }
    }
  }
}