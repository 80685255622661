.page-secret-sales {
    margin-top: 120px;

    @include media-breakpoint-down(md) {
        margin-top: 75px;
    }

    .card {
        h1 {
            color: $white;

            &::after {
                background: #c0c0c0;
            }
        }

        h3 {
            color: $white;
        }
    }

    .search-property-form {
        .form-control {
            border-color: $white;
            color: $white;
        }

        .cli-search {
            color: $white;
        }
    }

    .property-grid-top {
        .top-info {
            color: $white;
        }

        .filter {
            button {
                color: $white;
            }
        }
    }

    .property-grid-pagination {
        color: $white;

        a {
            color: $white;
        }

        .prev {
            &:after {
                background: $white;
            }
        }
    }
}