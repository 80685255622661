.image-grid {
    max-width: 1350px;

    .image-grid__row {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-auto-rows: 1fr;
        gap: 30px 30px;

        @include media-breakpoint-down(sm) {
            display: none;
        }

        &.is--mobile {
            display: none;
            grid-template-columns: 1fr 1fr;
            gap: 20px 20px;

            @include media-breakpoint-down(sm) {
                display: grid;
            }
        }


        img {
            opacity: 1;
            object-fit: cover;
            object-position: center;
            display: block;
            width: 100%;
            height: 420px;

            @include media-breakpoint-down(sm) {
                height: 240px;
            }
        }

        .image-grid__col {
            overflow: hidden;
            display: block;

            a {
                display: block;
                position: relative;
                @include grainOverlay(transparent, 1);
            }

            &:hover {
                img {
                    filter: brightness(0.7);
                }
            }
        }

        .image-grid__col-large {
            grid-column: span 2;

            a {
                display: block;
                position: relative;
                @include grainOverlay(transparent, 1);
            }


            &:hover {
                img {
                    filter: brightness(0.7);
                }
            }
        }
    }
}