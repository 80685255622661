.brick-newsletter-section {
    position: relative;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }

    .form2 {
        background-size: cover;
        background-position: center center;
        display: flex;
        justify-content: flex-end;
    }

    .form2 .form-container {
        max-width: 640px;
        width: 100%;
        padding-top: 65px;
        padding-left: 64px;
        padding-right: 69px;
        padding-bottom: 64px;
        position: relative;
        @include grainOverlay($navi, 0);

        .inner {
            z-index: 1;
        }
    }

    .form2 .form-container .inner {
        border: 1px solid #f2f2f2;
        padding-top: 117px;
        padding-left: 40px;
        padding-right: 40px;
        padding-bottom: 140px;
        position: relative;

        .brick-special-heading {
            margin-bottom: 30px;

            .first-title {
                max-width: unset;
            }
        }
    }

    .form2 .form-container .inner:after {
        content: "";
        width: calc(100% + 24px);
        height: calc(100% - 24px);
        position: absolute;
        top: 11px;
        left: -12px;
        border: 1px solid #f2f2f2;
    }

    .form2 .form-container .input-tel__input,
    .form2 .form-container .country-selector__input,
    .form2 .form-container .name,
    .form2 .form-container .email {
        margin-bottom: 13px;
        height: 61px;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.78;
        letter-spacing: 3.27px;
        color: white;
        background: #657579;
        padding-left: 27px;
        border-radius: 0 !important;

        &::placeholder {
            color: #b8b8b8;
        }
    }

    .form2 .form-container .input-tel__input,
    .form2 .form-container .country-selector__input {
        border: none;
    }

    .form2 .form-container .input-tel__label,
    .form2 .form-container .country-selector__label {
        color: white !important;
    }

    .form2 .form-container button[type="submit"] {
        margin: auto;
        display: block;
        max-width: 330px;
        width: 100%;
    }

    .form2 .form-container .custom-checkbox .custom-control-label {
        font-size: 14px;
        font-style: italic;
        line-height: 1.43;
        letter-spacing: normal;
        color: #f2f2f2;
        text-transform: none;
    }

    .form2 .form-container .custom-checkbox {
        margin-top: 24px;
        padding-left: 32px;
        margin-bottom: 45px;
    }

    .form2 .form-container .custom-checkbox .custom-control-label::before {
        border: 1px solid #f2f2f2;
        background: transparent;
        width: 17px;
        height: 17px;
    }

    .form2 .form-container .custom-control-input:checked ~ .custom-control-label::before {
        border-color: #f2f2f2;
        background: transparent;
    }

    .form2 .form-container .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
        font-size: 6px;
        transform: translate(-9px, -3px);
    }

    .form2 .form-container .custom-checkbox .custom-control-label a {
        color: inherit;
        text-decoration: underline;
    }

    .form2 .form-container .custom-checkbox .custom-control-label a:hover {
        text-decoration: none;
    }

    .form2 .form-container .inner-form {
        position: relative;
        z-index: 1;
    }

    @include media-breakpoint-down(sm) {
        .form2 .form-container {
            max-width: 100%;
            padding: 32px;
        }
        .form2 .form-container .inner {
            padding-top: 47px;
            padding-left: 35px;
            padding-right: 35px;
            padding-bottom: 61px;
        }
        .form2 .form-container .title {
            font-size: 23px;
        }
        .form2 .form-container .subtitle {
            font-size: 14px;
        }
        .form2 .form-container .text {
            font-size: 16px;
        }

        .form2 .form-container .form-control {
            height: 50px;
        }
        .form2 .form-container .custom-checkbox .custom-control-label {
            font-size: 13px;
        }
        .form2 .form-container .btn {
            font-size: 13px;
        }
    }
}
